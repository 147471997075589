import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGetBundlesOptionsQuery } from "../../store/bundlingsSlice";
import { useSelector } from "react-redux";
import { useGetAllSurveysQuery } from "../../store/suerveySlice";

export function DelaySelect({ metric, setMetric, defaultMetric = "" }) {
  const handleChange = (event) => {
    setMetric(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl fullWidth={true}>
        <Select
          labelId="demo-simple-delay-label"
          id="demo-simple-delay"
          value={metric !== "" ? metric : defaultMetric}
          onChange={handleChange}
        >
          {[
            { id: "minutes", label: "Minutes" },
            { id: "hours", label: "Hours" },
            { id: "days", label: "Days" },
            { id: "weeks", label: "Weeks" },
            { id: "months", label: "Months" },
          ].map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
const FormBasedCondition = ({
  selectConditionalBased,
  setSelectConditionalBased,
}) => {
  const { user } = useSelector((state) => state.user);
  const [formattedData, setFormattedData] = useState([]);
  const { data } = useGetBundlesOptionsQuery();
  const { data: SurveyData } = useGetAllSurveysQuery(user.id);
  const [alignment, setAlignment] = useState("bundle");
  const [delay, setDelay] = useState(
    selectConditionalBased?.timeRangeDelay?.delay || "1",
  );
  const [metric, setMetric] = useState(
    selectConditionalBased?.timeRangeDelay?.metric || "days",
  );

  const timeRanges = [
    { id: 1, title: "Anytime", value: "anytime" },
    { id: 2, title: "Custom Delay", value: "delay" },
  ];

  useEffect(() => {
    if (selectConditionalBased?.timeRange === "delay") {
      setSelectConditionalBased((prevState) => ({
        ...prevState,
        timeRangeDelay: { delay, metric, delayValue: Number.parseInt(delay) },
      }));
    }
  }, [delay, metric]);

  useEffect(() => {
    if (selectConditionalBased?.timeRangeDelay) {
      setDelay(selectConditionalBased.timeRangeDelay.delay || "1");
      setMetric(selectConditionalBased.timeRangeDelay.metric || "days");
    }
  }, [selectConditionalBased?.timeRangeDelay]);

  const handleChange = (e, type) => {
    const value = e.target.value;
    if (type === "timeRange") {
      setSelectConditionalBased((prevState) => ({
        ...prevState,
        timeRange: value,
      }));
    } else if (type === "bundle") {
      const selectedItem =
        data?.data?.find((item) => item.title === value) ||
        data?.data?.find((item) => item._id === value);
      setSelectConditionalBased((prevState) => ({
        ...prevState,
        id: selectedItem?.id || selectedItem?._id,
        title: selectedItem?.title || selectedItem?.form?.title,
        type: alignment,
      }));
    } else {
      const selectedItem =
        formattedData?.find((item) => item.title === value) ||
        SurveyData?.data?.find((item) => item._id === value);
      setSelectConditionalBased((prevState) => ({
        ...prevState,
        id: selectedItem?.id || selectedItem?._id,
        title: selectedItem?.title || selectedItem?.form?.title,
        type: alignment,
      }));
    }
  };

  const handleAlignment = (event, newAlignment) => {
    setSelectConditionalBased((prevState) => ({
      ...prevState,
      id: "",
      type: newAlignment,
    }));
    setAlignment(newAlignment);
  };

  useEffect(() => {
    if (data) {
      const formatData = (data) => {
        return Object.entries(data).map(([key, value]) => {
          const formattedKey = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
          return { id: value, title: formattedKey };
        });
      };

      const formatted = formatData(data);

      setFormattedData(formatted);
    }
  }, [data]);

  useEffect(() => {
    if (selectConditionalBased?.type) {
      setAlignment(selectConditionalBased?.type);
    }
  }, [selectConditionalBased]);

  return (
    <>
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
        sx={{ mb: 2 }}
      >
        <ToggleButton value="bundle" aria-label="left aligned">
          <Typography variant="subtitle2" fontWeight="600">
            Bundle
          </Typography>
        </ToggleButton>
        <ToggleButton value="form" aria-label="centered">
          <Typography variant="subtitle2" fontWeight="600">
            Form
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
      {alignment === "bundle" && (
        <FormControl
          fullWidth
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="demo-simple-form-label">Select Bundle</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-form-label"
            id="demo-simple-form"
            value={selectConditionalBased?.id || ""}
            label="Select Bundle"
            onChange={(e) => handleChange(e, "bundle")}
          >
            {data?.data?.map((item) => (
              <MenuItem
                key={item._id}
                value={item._id}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "590px",
                }}
              >
                {item?.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {alignment === "form" && (
        <FormControl
          fullWidth
          sx={{
            textAlign: "left",
          }}
        >
          <InputLabel id="demo-simple-form-label">Select Form</InputLabel>
          <Select
            fullWidth
            labelId="demo-simple-form-label"
            id="demo-simple-form"
            value={selectConditionalBased?.id || ""}
            label="Select Form"
            onChange={(e) => handleChange(e, "form")}
          >
            {SurveyData?.data?.map((item) => (
              <MenuItem
                key={item._id}
                value={item._id}
                sx={{
                  "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                    width: "100%",
                  },
                  width: "590px",
                }}
              >
                {item?.form?.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl
        fullWidth
        sx={{
          textAlign: "left",
          mt: 2,
        }}
      >
        <InputLabel id="demo-simple-form-label">Select Time Range</InputLabel>
        <Select
          fullWidth
          labelId="demo-simple-form-label"
          id="demo-simple-form"
          value={selectConditionalBased?.timeRange || ""}
          label="Select Time Range"
          onChange={(e) => handleChange(e, "timeRange")}
        >
          {timeRanges?.map((item) => (
            <MenuItem
              key={item._id}
              value={item.value}
              sx={{
                "&.css-6hp17o-MuiList-root-MuiMenu-list": {
                  width: "100%",
                },
                width: "590px",
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectConditionalBased?.timeRange === "delay" && (
        <Grid container alignItems="center" sx={{ mt: 2 }}>
          <Grid
            item={true}
            md={6}
            xs={6}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <p style={{ marginRight: "5px", fontWeight: 600 }}>Range</p>
            <TextField
              type="number"
              id="outlined-required"
              placeholder={`Enter ${metric}`}
              name="title"
              value={delay}
              onChange={(e) => {
                setDelay(e.target.value);
              }}
              inputProps={{ min: 1 }} // Prevents typing below 1
              sx={{
                width: "80%",
              }}
            />
          </Grid>
          <Grid
            item={true}
            md={6}
            xs={6}
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <p style={{ marginRight: "5px", fontWeight: 600 }}>Metric</p>
            <DelaySelect metric={metric} setMetric={setMetric} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FormBasedCondition;
