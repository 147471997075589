export const userDesignation = {
  SURGEON: 'Surgeon',
  PHYSICIAN: 'Physician',
  NURSE: 'Nurse',
  CARE_COORDINATOR: 'Care-Coordinator'
}

export const accessScope = {
  PRIVATE: 'private',
  PUBLIC: 'public'
}

export const filterType = {
  DASHBOARD: 'DASHBOARD',
  PATIENT_MANAGEMENT: 'PATIENT_MANAGEMENT'
}
