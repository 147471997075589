import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useIsMobile } from "../../../../contexts/isMobile";
import { useGetChatByPhoneMutation } from "../../../../store/callSlice";
import { SendUnknownMessageField } from "../../../../Components/MessageLayout/SendUnknownMessageField";
import { UnknownDisplayMessageBox } from "../../../../Components/MessageLayout/UnknownDisplayMessageBox";
import { ArrowBackIos } from "@mui/icons-material";

export default function UnknownConversationTab({
  id,
  isDrawer,
  selectedValue,
  chatBot,
  consent,
  status,
  value,
  AiAssistantValue,
  agentId,
  patient,
  isPatientQueueV2,
  phone,
  consentResponse,
  setUnknownPatientToggleOption,
}) {
  const [allMessages, setAllMessages] = useState([]);
  const [page, setPage] = useState(0);
  const isMobile = useIsMobile();
  const [isPrevPageLoading, setisPrevPageLoading] = useState(false); // Track loading state for messages and pagination
  const [showLoader, setShowLoader] = useState(false);
  const [patientQuestion, setPatientQuestion] = useState("");
  const [aiGeneratedMessage, setAiGeneratedMessage] = useState("");
  const [lastMessageId, setLastMessageId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const scrollableRef = useRef();
  const calculateScrollHeight = useRef();
  const prevDataRef = useRef();
  const sendMessageFieldRef = useRef();
  // const { data, refetch, isLoading } = useGetChatQuery({ id, sid: '2', page })
  const [getChatByPhone, { isLoading }] = useGetChatByPhoneMutation({ page });

  const fetchChatData = async () => {
    const res = await getChatByPhone({ phone });
    setData(res?.data?.data ? res?.data?.data : []);
  };

  const refetch = () => {
    fetchChatData();
  };

  useEffect(() => {
    calculateScrollHeight.current = scrollableRef.current?.scrollHeight;
    refetch();
  }, [page]);

  const refetchAgain = () => {
    refetch();
  };

  React.useEffect(() => {
    if (data?.length !== 0) {
      const interval = setInterval(() => {
        if (data?.length !== prevDataRef?.current?.length) {
          prevDataRef.current = data;
          const tempAllMessages = page === 1 ? data : allMessages.concat(data);
          setAllMessages(tempAllMessages);
          const sortedDate = [...tempAllMessages]?.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            return dateA - dateB;
          });

          setFilteredData(sortedDate);
          const userLastMessageArray = data?.filter(
            (item) => item.sentBy === "user",
          );
          const userLastMessage =
            userLastMessageArray?.[userLastMessageArray?.length - 1];
          setLastMessageId(userLastMessage?._id);
          setisPrevPageLoading(false);
        }
      }, 5000);

      const tempAllMessages = page === 1 ? data : allMessages.concat(data);
      setAllMessages(tempAllMessages);
      const sortedDate = [...tempAllMessages]?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
      });

      setFilteredData(sortedDate);
      const userLastMessageArray = data?.filter(
        (item) => item.sentBy === "user",
      );
      const userLastMessage =
        userLastMessageArray?.[userLastMessageArray?.length - 1];
      setLastMessageId(userLastMessage?._id);
      setisPrevPageLoading(false);
      prevDataRef.current = data;

      return () => clearInterval(interval);
    }
  }, [data]);

  useEffect(() => {
    scrollDown();
  }, [filteredData]);

  useEffect(() => {
    setPage(1);
  }, []);

  const scrollDown = () => {
    if (scrollableRef.current && page == 1) {
      scrollableRef.current.scrollTop = scrollableRef.current?.scrollHeight;
    } else if (scrollableRef.current && page > 1) {
      scrollableRef.current.scrollTop =
        scrollableRef.current?.scrollHeight - calculateScrollHeight.current;
    }
  };

  const adjustDisplayMessageBoxHeight = () => {
    if (sendMessageFieldRef.current && scrollableRef.current) {
      const sendMessageFieldHeight = sendMessageFieldRef.current.clientHeight;
      const maxHeight = "calc(100vh - 180px)";
      const adjustedHeight = `calc(${maxHeight} - ${sendMessageFieldHeight}px)`;
      scrollableRef.current.style.maxHeight = adjustedHeight;
      scrollDown();
    }
  };

  useEffect(() => {
    adjustDisplayMessageBoxHeight();
  }, [sendMessageFieldRef.current?.clientHeight]);

  const handleScroll = () => {
    const scrollTop = scrollableRef.current.scrollTop;
    if (scrollTop == 0) {
      // Near the top - fetch previous page
      setPage((prevPage) => prevPage + 1);
      setisPrevPageLoading(true);
    }
  };

  useEffect(() => {
    fetchChatData();
  }, []);

  if (isLoading) {
    return (
      <>
        <Stack spacing={1} py={2}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={360}
            height={100}
          />
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width={360}
              height={100}
            />
          </div>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={360}
            height={100}
          />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: 2,
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            setUnknownPatientToggleOption("notes");
          }}
        >
          <ArrowBackIos />
        </IconButton>
        <Typography>All Messages</Typography>
      </Box>
      {isPrevPageLoading && (
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            color: "gray",
          }}
        >
          Fetching Messages . . .
        </Typography>
      )}
      {filteredData && filteredData?.length > 0 ? (
        <Box
          className="ScrollDesign"
          ref={scrollableRef}
          sx={{
            overflowY: "auto", // Enable vertical scrolling if needed
            marginBottom: "100px",
            p: 1,
          }}
          onScroll={handleScroll}
        >
          <UnknownDisplayMessageBox
            data={filteredData}
            chatBot={chatBot}
            consent={consent}
            selectedValue={selectedValue}
            setShowLoader={setShowLoader}
            setAiGeneratedMessage={setAiGeneratedMessage}
            setPatientQuestion={setPatientQuestion}
            patient={patient}
          />
        </Box>
      ) : (
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 600,
            mt: "2rem",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          No Messages Found.
        </Typography>
      )}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "58%",
          background: "white",
        }}
      >
        <div ref={sendMessageFieldRef}>
          <SendUnknownMessageField
            setPage={setPage}
            scrollDown={scrollDown}
            phone={phone}
            refetchAgain={refetchAgain}
            data={filteredData}
            setData={setFilteredData}
            consentResponse={consentResponse}
            showLoader={showLoader}
            aiGeneratedMessage={aiGeneratedMessage}
          />
        </div>
      </Box>
    </>
  );
}
