import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DateField, SolidButton } from "../../../../Components";
import TimeFieldPicker from "../../../../Components/TimeField";
import { useChildNode } from "../../../../contexts/addChildNode";
import { useIsMobile } from "../../../../contexts/isMobile";
import { useGetAllJourneysQuery } from "../../../../store/journeysSlice";
import { useGetPhysiciansOptionsQuery } from "../../../../store/physiciansSlice";
import {
  useGetProceduresOptionsQuery,
  useGetProceduresStatusQuery,
} from "../../../../store/proceduresSlice";
import {
  useAddNewSurgeryMutation,
  useEditSurgeryMutation,
} from "../../../../store/surgeriesSlice";
import {
  useAssignCarePathwayJourneysMutation,
  useGetCarePathwayQuery,
} from "../../../../store/carepathwaysSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const JourneyDropdown = ({
  triggerJourney,
  setTriggerJourney,
  journeyListData,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // Store the _id instead of title
    setTriggerJourney(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <div>
      <FormControl variant="standard" fullWidth>
        <Select
          required
          id="demo-multiple-checkbox"
          multiple
          value={triggerJourney}
          onChange={handleChange}
          renderValue={(selected) =>
            selected
              .map(
                (id) =>
                  journeyListData?.data?.find((src) => src._id === id)?.title,
              )
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {journeyListData?.data?.map((src) => (
            <MenuItem key={src._id} value={src._id}>
              <Checkbox checked={triggerJourney.indexOf(src._id) > -1} />
              <ListItemText primary={src.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export function SurgeryModal({
  open,
  setOpen,
  fetechAgain,
  setEditData,
  editData,
  status,
  patientId,
}) {
  const { procedureReferesh, setProcedureReferesh } = useChildNode();
  const { user } = useSelector((state) => state.user);
  const { id } = useParams();
  const isMobile = useIsMobile();
  const [surgeryDate, setSurgeryDate] = useState("");
  const [time, setTime] = useState("");
  const [surgeryTime, setSurgeryTime] = useState({});
  const [surgeon, setSurgeon] = useState("");
  const [selectedSurgeon, setSelectedSurgeon] = useState(null);
  const [selectedSurgeryType, setSelectedSurgeryType] = useState(null);
  const [selectedSurgeryStatus, setSelectedSurgeryStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [CarePathwaysList, setCarePathwaysList] = useState([]);
  const [selectCarePathway, setSelectCarePathway] = useState([]);
  const [selectionMode, setSelectionMode] = useState("manual");
  const { data: carePathwayData } = useGetCarePathwayQuery();
  const { data: journeyListData, refetch: refetchJourneyList } =
    useGetAllJourneysQuery({
      id: user.id,
      params: "exclude=appointment",
      procedureId: editData?._id,
    });
  const { data: procedureTypeData, isLoading: isProcedureLoading } =
    useGetProceduresOptionsQuery();
  const { data: procedureStatusData, isLoading: isStatusLoading } =
    useGetProceduresStatusQuery();
  const { data: physicianOptions, isLoading: isPhysicianLoading } =
    useGetPhysiciansOptionsQuery();
  const [
    assignCarePathwayJourneys,
    { isLoading: isAssignCarePathwayJourneysLoading },
  ] = useAssignCarePathwayJourneysMutation();
  const [triggerJourney, setTriggerJourney] = useState([]);
  const [form, setForm] = useState({
    description: "",
    location: "",
    surgeryType: "",
    patient: patientId ? patientId : id,
    careTeamMember: user.id,
  });
  const [addNewSurgery, { isLoading: isAddSurgeryLoading }] =
    useAddNewSurgeryMutation();
  const [editSurgery, { isLoading: isEditSurgeryLoading }] =
    useEditSurgeryMutation();

  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const resetAllValues = () => {
    setForm({
      description: "",
      location: "",
      surgeryType: "",
      patient: patientId ? patientId : id,
      careTeamMember: user.id,
    });
    setTriggerJourney([]);
    setSurgeryDate("");
    setSelectedSurgeryType(null);
    setEditData({});
    setSurgeon("");
    setTime();
    setSelectedSurgeon(null);
    setSurgeryTime({});
    setSelectedSurgeryStatus(null);
    setSelectCarePathway([]);
  };

  useEffect(() => {
    if (carePathwayData?.data) {
      const newResult = Object.entries(carePathwayData?.data)?.map(
        ([key, value]) => {
          return {
            _id: value,
            title: key,
          };
        },
      );

      setCarePathwaysList(newResult);
    }
  }, [carePathwayData]);

  const handleCarePathwayChange = (event, newValue) => {
    // Extract values from newValue array of objects
    const values = newValue.map((item) => item._id);

    setSelectCarePathway(
      // On autofill we get a stringified value.
      typeof newValue === "string" ? newValue?.split(",") : newValue,
    );
  };

  const handleSurgeryChange = (e, newValue) => {
    setSelectedSurgeryType(newValue);
    setForm((form) => ({ ...form, surgeryType: newValue?._id }));
  };

  const handleStatusChange = (e, newValue) => {
    setSelectedSurgeryStatus(newValue);
    setForm((form) => ({ ...form, surgeryStatus: newValue?.value }));
  };

  const createSurgery = async (e) => {
    const isFormValid = () => {
      if (
        surgeryDate === "" ||
        surgeon === "" ||
        form.surgeryType === "" ||
        !form.surgeryStatus
      ) {
        toast.error("Please fill out the required fields");
        return false;
      }
      return true;
    };

    if (status !== "active") {
      toast.error("The patient status is currently inactive.");
      resetAllValues();
      setOpen(false);
      return;
    }

    if (!isFormValid()) {
      return;
    }

    const body = {
      ...form,
      surgeryType: form?.surgeryType,
      surgeon,
      surgeryDate,
      ...(triggerJourney?.length > 0 && { journeyIds: triggerJourney }),
      surgeryTime,
    };

    try {
      if (editData?._id) {
        // Editing existing surgery
        await editSurgery({ id: editData?._id, body }).unwrap();
        toast.success("Successfully Edited!");
      } else {
        // Adding a new surgery
        const result = await addNewSurgery(body).unwrap(); // Assuming result contains the new surgery ID
        const surgeryId = result?.data?._id; // Extract the newly created surgery ID

        if (selectionMode === "carePathway" && selectCarePathway?.length > 0) {
          // Assign care pathway journeys
          await assignCarePathwayJourneys({
            carePathways: selectCarePathway,
            patientId: patientId ? patientId : id,
            assignPathwayJourneys: true,
            ...(surgeryId && { procedureId: surgeryId }),
          });
          toast.success("Care Pathway Journeys assigned successfully!");
        }

        if (triggerJourney?.length > 0) {
          toast.success("Procedure created and Journey assigned successfully!");
        } else {
          toast.success("Procedure created successfully!");
        }
      }

      fetechAgain();
      setProcedureReferesh(procedureReferesh + 1);
      resetAllValues();
      setOpen(false);
    } catch (error) {
      const errorMessage =
        error?.data?.error || error?.data || "An unexpected error occurred.";
      toast.error(errorMessage);
    }
  };

  const handleClose = () => {
    resetAllValues();
    setOpen(false);
  };

  const handleSurgeonChange = (event, newValue) => {
    setSelectedSurgeon(newValue);
    setSurgeon(newValue?._id);
  };

  useEffect(() => {
    if (procedureStatusData?.data) {
      const transformedOptions = Object.entries(procedureStatusData.data).map(
        ([key, value]) => ({ name: value, value: key }),
      );
      setStatusOptions(transformedOptions);
    }
  }, [procedureStatusData]);

  useEffect(() => {
    if (editData) {
      const filteredSurgeryType = procedureTypeData?.data?.find(
        (item) => item?.name === editData?.surgeryType,
      );
      const filteredSurgeryStatus = statusOptions?.find(
        (item) => item?.name === editData?.surgeryStatus,
      );

      setForm({
        description: editData?.description || "",
        location: editData?.location || "",
        patient: patientId ? patientId : id,
        careTeamMember: editData?.careTeamMember || user.id,
        surgeryType: filteredSurgeryType?._id || "",
        surgeryStatus: filteredSurgeryStatus?.name || null,
      });

      setSurgeon(editData?.surgeon?._id);
      setSelectedSurgeon(editData?.surgeon);
      setSelectedSurgeryType(filteredSurgeryType);
      setSurgeryDate(
        editData?.surgeryDate ? new Date(editData?.surgeryDate) : "",
      );
      setSelectedSurgeryStatus(filteredSurgeryStatus);

      if (editData?.surgeryTime?.hour) {
        const { hour, minute } = editData?.surgeryTime;

        // Create a new date object
        const newDate = new Date();

        // Set the hours and minutes
        newDate.setHours(
          Number.parseInt(hour, 10),
          Number.parseInt(minute, 10),
          0,
          0,
        );

        // Convert to string format like 'Thu Apr 04 2024 08:00:00 GMT+0500 (Pakistan Standard Time)'
        const formattedDate = newDate.toString();

        setTime(new Date(formattedDate));
      } else {
        setTime("");
      }

      setSurgeryTime(editData?.surgeryTime);
    }
  }, [editData]);

  useEffect(() => {
    refetchJourneyList();
  }, []);

  const handleSelectionModeChange = (event) => {
    setSelectionMode(event.target.value);
  };

  if (
    isProcedureLoading ||
    isAssignCarePathwayJourneysLoading ||
    isStatusLoading
  ) {
    return <>{console.log("Loading")}</>;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 750,
          height: isMobile ? "60%" : "80%",
          bgcolor: "#F5F5F5",
          p: 4,
          overflowY: "auto",
          borderRadius: "10px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Procedure
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              resetAllValues();
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Box>
        </Stack>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color="#979797" mt={2}>
                Surgeon Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeon}
                onChange={(e, newValue) => handleSurgeonChange(e, newValue)}
                options={
                  !isPhysicianLoading && Array.isArray(physicianOptions)
                    ? physicianOptions
                    : []
                }
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color="#979797" mt={2}>
                Procedure Type <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeryType}
                onChange={(e, newValue) => handleSurgeryChange(e, newValue)}
                options={procedureTypeData?.data}
                getOptionLabel={(option) => option?.name}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color="#979797" mt={2}>
                Procedure Status <span style={{ color: "red" }}>*</span>
              </Typography>
              <Autocomplete
                value={selectedSurgeryStatus}
                onChange={(e, newValue) => handleStatusChange(e, newValue)}
                options={statusOptions}
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color="#979797">
                Procedure Date <span style={{ color: "red" }}>*</span>
              </Typography>
              <DateField date={surgeryDate} setDate={setSurgeryDate} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color="#979797">
                Procedure Time
              </Typography>
              <TimeFieldPicker
                date={time}
                setDate={setTime}
                isSurgery={true}
                setHourToRunAt={setSurgeryTime}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography fontSize={12} color="#979797" mb={4}>
                Location
              </Typography>
              <TextField
                variant="standard"
                fullWidth
                name="location"
                value={form.location}
                onChange={onChange}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  value={selectionMode}
                  onChange={handleSelectionModeChange}
                  name="row-radio-buttons-group"
                  sx={{ width: "max-content" }}
                >
                  <FormControlLabel
                    value="carePathway"
                    control={<Radio />}
                    label="Select Care Pathways"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                  <FormControlLabel
                    value="manual"
                    control={<Radio />}
                    label="Select Journeys Manually"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {selectionMode === "manual" && (
              <Grid item md={12} xs={12}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography fontSize={12} color="#979797" mb={1}>
                    Select Journey
                  </Typography>
                  <Tooltip
                    title="Select journey to link with this procedure"
                    placement="right-start"
                  >
                    <Box
                      sx={{
                        color: "grey",
                        paddingLeft: "3px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Box>
                  </Tooltip>
                </Box>
                <JourneyDropdown
                  {...{ triggerJourney, setTriggerJourney, journeyListData }}
                />
              </Grid>
            )}
            {selectionMode === "carePathway" && (
              <Grid item md={12} xs={12}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography fontSize={12} color="#979797" mb={1}>
                    Select Care Pathway
                  </Typography>
                  <Tooltip
                    title="Select Care Pathway to link with this procedure"
                    placement="right-start"
                  >
                    <Box
                      sx={{
                        color: "grey",
                        paddingLeft: "3px",
                        cursor: "pointer",
                      }}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Box>
                  </Tooltip>
                </Box>
                <Autocomplete
                  multiple
                  value={selectCarePathway || []}
                  onChange={(e, newValue) =>
                    handleCarePathwayChange(e, newValue)
                  }
                  options={CarePathwaysList}
                  getOptionLabel={(option) => option?.title}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Typography fontSize={12} color="#979797" mt={2}>
                Description
              </Typography>
              <TextField
                variant="standard"
                fullWidth
                name="description"
                multiline
                rows={4}
                value={form.description}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
          >
            <SolidButton
              label="Assign"
              onClick={createSurgery}
              disabled={isAddSurgeryLoading || isEditSurgeryLoading}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
