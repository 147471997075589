import { Box, Button, Drawer, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Call } from "../../../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Conversation } from "../../../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { useIsMobile } from "../../../../contexts/isMobile";
import UnknownCallTab from "./UnknownCallTab";
import UnknownConversationTab from "./UnknownConversationTab";
import { UnknownDrawerHeader } from "./UnknownDrawerHeader";
import { toast } from "react-toastify";
import OutgoingCallComponent from "../../../../Components/OutgoingCall";
import UnknownContentDrawer from "./UnknownCallerSummary";
import IgnoreModal from "../IgnoreModal";
import axios from "axios";
import { getTokenFromLocalStroage } from "../../../../utils";

export function UnknownCallerMessageDrawer({
  state,
  toggleDrawer,
  setOpenUnknownCallerChat,
  refetchQueueAgain,
}) {
  const isMobile = useIsMobile();
  const [isModalOpen, setModalOpen] = useState(false);
  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };
  const [unknownPatientToggleOption, setUnknownPatientToggleOption] =
    useState("notes");
  const [openCallUnregisterPatient, setOpenCallUnregisterPatient] =
    useState(false);
  const handleCloseCallUnregister = () => {
    setOpenCallUnregisterPatient(false);
  };

  useEffect(() => {
    if (state?.isMessageIconClick) {
      setUnknownPatientToggleOption("messages");
    } else {
      setUnknownPatientToggleOption("notes");
    }
  }, [state?.value]);

  const openCallUnregisterPatientModal = (name, phone, patientId) => {
    const usPhoneRegex = /^\+1\d{10}$/;
    if (phone && usPhoneRegex.test(phone)) {
      setOpenCallUnregisterPatient({
        value: true,
        name: name,
        phone: phone,
        patientId: patientId,
      });
    } else {
      toast.error("Phone number is not valid!");
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleIgnoreConfirm = () => {
    updateIgnoredStatus([state?.data]);
    setModalOpen(false);
    toggleDrawer(false)();
  };

  const updateIgnoredStatus = async (allIds) => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatuses`,
          { status: "ignored", ids: allIds },
          config,
        )
        .then((res) => {
          const status = res.data.status;
          toast.success("The issue is " + status);
          refetchQueueAgain();
        });
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  return (
    <React.Fragment key="anchor">
      <Drawer
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflow: "hidden",
            width: isMobile ? "90%" : "60%",
          },
        }}
        anchor="right"
        open={state?.value}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 990,
          }}
        >
          <UnknownDrawerHeader
            data={state}
            setOpenUnknownCallerChat={setOpenUnknownCallerChat}
            refetchQueueAgain={refetchQueueAgain}
            toggleDrawer={toggleDrawer}
          />
        </Box>

        <Box
          sx={{
            padding: "10px",
            height:
              unknownPatientToggleOption === "notes" && "calc(100vh - 200px)", // Fixed spacing
            overflowY: unknownPatientToggleOption === "notes" && "auto", // Allow scroll behavior
          }}
          className={
            unknownPatientToggleOption === "messages" ? "" : "DialogContent"
          }
        >
          {unknownPatientToggleOption === "messages" && (
            <UnknownConversationTab
              {...{
                phone: state?.results?.phone_number,
                consentResponse: state?.results?.consentResponse,
                setUnknownPatientToggleOption,
              }}
            />
          )}
          {unknownPatientToggleOption === "notes" && (
            <UnknownContentDrawer
              {...{
                queueId: state?.data,
                type: state?.calldata?.type,
                setUnknownPatientToggleOption,
              }}
            />
          )}

          {unknownPatientToggleOption === "calls" && (
            <UnknownCallTab
              {...{
                queueId: state.data,
                taskCategory: state.taskCategory,
                data: state,
                setUnknownPatientToggleOption,
              }}
            />
          )}
        </Box>
        {(unknownPatientToggleOption === "notes" ||
          unknownPatientToggleOption === "calls") && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              position: "absolute", // Make it fixed
              bottom: 0, // Align it at the bottom
              right: 10,
              backgroundColor: "white", // To ensure the background stays visible on top of other content
              zIndex: 1000, // Ensure it stays above other elements
              paddingY: 2,
              width: "100%",
            }}
          >
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                onClick={handleModalOpen}
                sx={{ fontWeight: 600 }}
                color="error"
              >
                Ignore
              </Button>

              <Button
                variant="outlined"
                startIcon={<Call color="#6235ED" />}
                sx={{ fontWeight: 600 }}
                onClick={() =>
                  openCallUnregisterPatientModal(
                    state?.calldata?.metadata?.patientData?.name,
                    state?.calldata?.metadata?.patientData?.phone_number,
                    state?.calldata?._id,
                  )
                }
              >
                Call Patient
              </Button>
              <Button
                variant="outlined"
                startIcon={<Conversation color="#6235ED" />}
                sx={{ fontWeight: 600 }}
                onClick={() => {
                  setUnknownPatientToggleOption("messages");
                }}
              >
                Send Message
              </Button>
            </Stack>
          </Box>
        )}
      </Drawer>
      <IgnoreModal
        open={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleIgnoreConfirm}
        title="Confirm Ignore"
        content="Are you sure you want to ignore this patient queue?"
      />
      {openCallUnregisterPatient?.value && (
        <OutgoingCallComponent
          open={openCallUnregisterPatient?.value}
          name={openCallUnregisterPatient?.name}
          phone={openCallUnregisterPatient?.phone}
          patientId={openCallUnregisterPatient?.patientId}
          handleClose={handleCloseCallUnregister}
        />
      )}
    </React.Fragment>
  );
}
