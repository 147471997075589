import { Box, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addInParams } from "../utils";
import { NorthRounded, SouthRounded } from "@mui/icons-material";

const timeFrameData = [
  {
    title: "Procedure Type",
    value: "desc",
    keyField: "procedureType",
  },
  {
    title: "Contact Time",
    value: "desc",
    keyField: "contactTime",
  },
  {
    title: "Acuity",
    value: "desc",
    keyField: "acuity",
  },
];

export default function QueueSort({
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  setPage,
}) {
  const navigate = useNavigate();

  const handleChipClick = (keyField) => {
    // Toggle the sort order if the same field is clicked again
    const newOrder =
      sortField === keyField && sortOrder === "desc" ? "asc" : "desc";

    localStorage.setItem("sortField", keyField);
    localStorage.setItem("sortOrder", newOrder);

    setSortField(keyField);
    setSortOrder(newOrder);
    localStorage.setItem("page", 1);
    addInParams("page", 1);
    setPage(1);
    addInParams("sortField", keyField);
    addInParams("sortOrder", newOrder);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          ml: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography sx={{ marginRight: "8px" }} color={"#B3B7BA"}>
            Sort by:
          </Typography>
          {timeFrameData?.map((values, index) => (
            <Chip
              key={index}
              label={values.title}
              icon={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <NorthRounded
                    fontSize="10px"
                    sx={{
                      color:
                        sortField === values.keyField && sortOrder === "asc"
                          ? "primary.main"
                          : "action.disabled",
                    }}
                  />
                  <SouthRounded
                    fontSize="10px"
                    sx={{
                      ml: "-4px",
                      color:
                        sortField === values.keyField && sortOrder === "desc"
                          ? "primary.main"
                          : "action.disabled",
                    }}
                  />
                </Box>
              }
              onClick={() => handleChipClick(values.keyField)}
              sx={{
                marginRight: "8px",
                borderRadius: "4px",
                border: "1px solid rgb(0,0,0,0.12)",
                backgroundColor:
                  sortField === values.keyField
                    ? "rgba(98, 53, 237, 0.08)"
                    : "white",
                color: "text.primary",
              }}
            />
          ))}
        </Box>
      </Box>
    </>
  );
}
