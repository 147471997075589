import { useEffect, useState } from "react";
import { CircularLoader, InactivityCheck } from "../../Components";
import Grid from "@mui/material/Grid";
import { UpperAnalytics } from "./UpperAnalytics";
import { FilterSidebar } from "./FilterSidebar";
import { useGetProceduresOptionsQuery } from "../../store/proceduresSlice";
import { toSnakeCase } from "../../utils";
import { patientQueueFilterData } from "../../FilterUtils";
import {
  useGetPatientQueueFilterQuery,
  useGetPatientQueuesQuery,
} from "../../store/patientQueuesSlice";
import { useSelector } from "react-redux";
import { useGetPhysiciansOptionsQuery } from "../../store/physiciansSlice";
import filterByDate from "../patientQueue/DateFilterFunction";
import { useChildNode } from "../../contexts/addChildNode";
import { useLocation } from "react-router-dom";
import { addInParams } from "../../utils";
import { useGetCarePathwayQuery } from "../../store/carepathwaysSlice";
import { useGetUserForTaskQuery } from "../../store/userManagmentSlice";
import { PatientQueue } from "./PatientQueue";

export function DashboardV2() {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);

  const { debouncedValue, showAnalytics } = useChildNode();

  const [page, setPage] = useState(
    queryParams.get("page")
      ? Number.parseInt(queryParams.get("page"))
      : localStorage.getItem("page")
        ? Number.parseInt(localStorage.getItem("page"))
        : 1,
  );
  const [filterData, setFilterData] = useState({});
  const [queriesValue, setQueriesValue] = useState(
    localStorage.getItem("queriesValue")
      ? JSON.parse(localStorage.getItem("queriesValue"))
      : {},
  );
  const [selectedPatientIds, setSelectedPatientIds] = useState([]);

  // Sorting states
  const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState(
    localStorage.getItem("sortCreatedAtOrder")
      ? JSON.parse(localStorage.getItem("sortCreatedAtOrder"))
      : { field: "createdAt", sort: "desc" },
  );
  const [sortAcuityOrder, setSortAcuityOrder] = useState(
    localStorage.getItem("sortAcuityOrder")
      ? JSON.parse(localStorage.getItem("sortAcuityOrder"))
      : { field: "acuity", sort: "asc" },
  );
  const [sortPatientOrder, setSortPatientOrder] = useState(
    localStorage.getItem("sortPatientOrder")
      ? JSON.parse(localStorage.getItem("sortPatientOrder"))
      : { field: "patientName", sort: "asc" },
  );

  const [sortTaskCategoryOrder, setSortTaskCategoryOrder] = useState(
    localStorage.getItem("sortTaskCategoryOrder")
      ? JSON.parse(localStorage.getItem("sortTaskCategoryOrder"))
      : { field: "taskCategory", sort: "asc" },
  );

  const [sortPhysicianNameOrder, setSortPhysicianNameOrder] = useState(
    localStorage.getItem("sortPhysicianNameOrder")
      ? JSON.parse(localStorage.getItem("sortPhysicianNameOrder"))
      : { field: "surgeonName", sort: "asc" },
  );

  const [sortProcedureTypeOrder, setSortProcedureTypeOrder] = useState(
    localStorage.getItem("sortProcedureTypeOrder")
      ? JSON.parse(localStorage.getItem("sortProcedureTypeOrder"))
      : { field: "procedureType", sort: "asc" },
  );

  const [sortQueueTypeOrder, setSortQueueTypeOrder] = useState(
    localStorage.getItem("sortQueueTypeOrder")
      ? JSON.parse(localStorage.getItem("sortQueueTypeOrder"))
      : { field: "queueType", sort: "asc" },
  );

  const [sortSocialNeedsOrder, setSortSocialNeedsOrder] = useState(
    localStorage.getItem("sortSocialNeedsOrder")
      ? JSON.parse(localStorage.getItem("sortSocialNeedsOrder"))
      : { field: "socialNeeds", sort: "asc" },
  );

  const [sortContactTimeOrder, setSortContactTimeOrder] = useState(
    localStorage.getItem("sortContactTimeOrder")
      ? JSON.parse(localStorage.getItem("sortContactTimeOrder"))
      : { field: "contactTime", sort: "asc" },
  );

  const [sortField, setSortField] = useState(
    localStorage.getItem("sortField")
      ? localStorage.getItem("sortField")
      : "contactTime",
  );
  const [sortSurgeonName, setSortSurgeonName] = useState(
    localStorage.getItem("sortSurgeonName")
      ? JSON.parse(localStorage.getItem("sortSurgeonName"))
      : { field: "surgeonName", sort: "asc" },
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder")
      ? localStorage.getItem("sortOrder")
      : "desc",
  );
  const [createdAfter, setCreatedAfter] = useState(
    localStorage.getItem("createdAfter")
      ? localStorage.getItem("createdAfter")
      : "",
  );
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue")
      ? localStorage.getItem("selectedValue")
      : "all",
  );
  const queries = {};

  queries["name"] = debouncedValue;
  queries["acuity"] = queriesValue?.acuity || [];
  queries["status"] = queriesValue?.status || "pending";
  queries["taskCategory"] =
    queriesValue?.taskCategory?.map((item) => item.value) || [];
  queries["carePathways"] =
    queriesValue?.program?.map((item) => item.value) || [];
  queries["clinic"] = queriesValue?.clinic?.map((item) => item.value) || [];
  queries["surgeryType"] =
    queriesValue?.surgeryType?.map((item) => item.id) || [];
  queries["surgeons"] = queriesValue?.surgeon?.map((item) => item.id) || [];
  queries["assignees"] = queriesValue?.assignee?.map((item) => item?.id) || [];
  queries["types"] = queriesValue?.types || [];

  const { data: filterddata } = useGetPatientQueueFilterQuery();
  const { data: procedureTypeData, isLoading } = useGetProceduresOptionsQuery();

  const { data: physicianOptions, isLoading: isPhysicianLoading } =
    useGetPhysiciansOptionsQuery();

  const {
    data,
    refetch,
    isLoading: isQueueLoading,
    isSuccess,
  } = useGetPatientQueuesQuery(
    {
      page,
      userId: user.id,
      sortField,
      sortOrder,
      createdAfter,
      ...queries,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: carePathwayData } = useGetCarePathwayQuery();

  const { data: userData } = useGetUserForTaskQuery();

  const refetchQueueAgain = () => {
    refetch();
  };

  const handleSorting = (field, isCreatedAt) => {
    if (field === sortCreatedAtOrder.field) {
      setSortCreatedAtOrder(
        sortCreatedAtOrder.sort === "asc"
          ? { field: "createdAt", sort: "desc" }
          : { field: "createdAt", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortCreatedAtOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortCreatedAtOrder",
        JSON.stringify(
          sortCreatedAtOrder.sort === "asc"
            ? { field: "createdAt", sort: "desc" }
            : { field: "createdAt", sort: "asc" },
        ),
      );
    } else if (field === sortAcuityOrder.field) {
      setSortAcuityOrder(
        sortAcuityOrder.sort === "asc"
          ? { field: "acuity", sort: "desc" }
          : { field: "acuity", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortAcuityOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortAcuityOrder",
        JSON.stringify(
          sortAcuityOrder.sort === "asc"
            ? { field: "acuity", sort: "desc" }
            : { field: "acuity", sort: "asc" },
        ),
      );
    } else if (field === sortPhysicianNameOrder.field) {
      setSortPhysicianNameOrder(
        sortPhysicianNameOrder.sort === "asc"
          ? { field: "surgeonName", sort: "desc" }
          : { field: "surgeonName", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortPhysicianNameOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortPhysicianNameOrder",
        JSON.stringify(
          sortPhysicianNameOrder.sort === "asc"
            ? { field: "surgeonName", sort: "desc" }
            : { field: "surgeonName", sort: "asc" },
        ),
      );
    } else if (field === sortTaskCategoryOrder.field) {
      setSortTaskCategoryOrder(
        sortTaskCategoryOrder.sort === "asc"
          ? { field: "taskCategory", sort: "desc" }
          : { field: "taskCategory", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortTaskCategoryOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortTaskCategoryOrder",
        JSON.stringify(
          sortTaskCategoryOrder.sort === "asc"
            ? { field: "taskCategory", sort: "desc" }
            : { field: "taskCategory", sort: "asc" },
        ),
      );
    } else if (
      sortProcedureTypeOrder.field === "procedureType" &&
      field === "surgeryType"
    ) {
      setSortProcedureTypeOrder(
        sortProcedureTypeOrder.sort === "asc"
          ? { field: "procedureType", sort: "desc" }
          : { field: "procedureType", sort: "asc" },
      );
      setSortField("procedureType");
      setSortOrder(sortProcedureTypeOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortProcedureTypeOrder",
        JSON.stringify(
          sortProcedureTypeOrder.sort === "asc"
            ? { field: "procedureType", sort: "desc" }
            : { field: "procedureType", sort: "asc" },
        ),
      );
    } else if (field === sortQueueTypeOrder.field) {
      setSortQueueTypeOrder(
        sortQueueTypeOrder.sort === "asc"
          ? { field: "queueType", sort: "desc" }
          : { field: "queueType", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortQueueTypeOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortQueueTypeOrder",
        JSON.stringify(
          sortQueueTypeOrder.sort === "asc"
            ? { field: "queueType", sort: "desc" }
            : { field: "queueType", sort: "asc" },
        ),
      );
    } else if (field === sortSocialNeedsOrder.field) {
      setSortSocialNeedsOrder(
        sortSocialNeedsOrder.sort === "asc"
          ? { field: "socialNeeds", sort: "desc" }
          : { field: "socialNeeds", sort: "asc" },
      );
      setSortField(field);
      setSortOrder(sortSocialNeedsOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortSocialNeedsOrder",
        JSON.stringify(
          sortSocialNeedsOrder.sort === "asc"
            ? { field: "socialNeeds", sort: "desc" }
            : { field: "socialNeeds", sort: "asc" },
        ),
      );
    }

    if (sortPatientOrder.field === "patientName" && field === "patientInfo") {
      setSortPatientOrder(
        sortPatientOrder.sort === "asc"
          ? { field: "patientName", sort: "desc" }
          : { field: "patientName", sort: "asc" },
      );
      setSortField("patientName");
      setSortOrder(sortPatientOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortPatientOrder",
        JSON.stringify(
          sortPatientOrder.sort === "asc"
            ? { field: "patientName", sort: "desc" }
            : { field: "patientName", sort: "asc" },
        ),
      );
    }

    if (isCreatedAt) {
      setSortContactTimeOrder(
        sortContactTimeOrder.sort === "asc"
          ? { field: "contactTime", sort: "desc" }
          : { field: "contactTime", sort: "asc" },
      );
      setSortField("contactTime");
      setSortOrder(sortContactTimeOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortContactTimeOrder",
        JSON.stringify(
          sortContactTimeOrder.sort === "asc"
            ? { field: "contactTime", sort: "desc" }
            : { field: "contactTime", sort: "asc" },
        ),
      );
    }

    localStorage.setItem(
      "sortField",
      isCreatedAt
        ? "contactTime"
        : field === "patientInfo"
          ? "patientName"
          : field,
    );
    localStorage.setItem("sortOrder", sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    if (procedureTypeData?.data) {
      const surgeryType = {
        value: procedureTypeData?.data?.map((item) => ({
          id: item._id,
          title: item.name,
          value: toSnakeCase(item.name),
        })),
      };

      const surgeon = {
        value: filterddata?.data?.surgeons
          ? Object.keys(filterddata.data.surgeons).map((key) => ({
              id: filterddata.data.surgeons[key],
              title: key,
              value: toSnakeCase(key),
            }))
          : [],
      };
      // const assignee = {
      // 	value: filterddata?.data?.assignee
      // 		? Object.keys(filterddata.data.assignee).map((key) => ({
      // 				id: filterddata.data.assignee[key],
      // 				title: key,
      // 				value: toSnakeCase(key),
      // 		  }))
      // 		: [],
      // }

      const clinic = {
        value: filterddata?.data?.clinic
          ? Object.entries(filterddata.data.clinic).map(([key, value]) => ({
              id: value,
              title: key,
              value: value,
            }))
          : [],
      };

      const program = {
        value: carePathwayData?.data
          ? Object.entries(carePathwayData.data).map(([key, value]) => ({
              id: value,
              title: key,
              value: value,
            }))
          : [],
      };

      const assignee = {
        value: userData?.data
          ? userData.data.map((item) => ({
              id: item._id,
              title: item.name,
              value: item._id,
            }))
          : [],
      };

      const newPatientQueueFilterData = {
        ...patientQueueFilterData,
        clinic,
        program,
        surgeryType,
        surgeon,
        assignee,
      };

      setFilterData(newPatientQueueFilterData);
    } else {
      setFilterData(patientQueueFilterData);
    }
  }, [
    procedureTypeData,
    physicianOptions,
    filterddata,
    carePathwayData,
    userData,
  ]);

  // This useEffect set the selectedIds null when filter change
  useEffect(() => {
    if (queriesValue?.status) {
      setSelectedPatientIds([]);
    }
  }, [queriesValue]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setPage(1);
      const filteredDate = filterByDate(selectedValue);
      localStorage.setItem("createdAfter", filteredDate);
      setCreatedAfter(filteredDate);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!queryParams.get("page") || !queryParams.get("status")) {
      addInParams("page", 1);
      addInParams("status", "pending");
    }
  }, []);

  useEffect(() => {
    // Parse each query parameter, including surgeryType as an array
    const acuityQueryValue = queryParams.get("acuity")?.split(",") || "";
    const taskCategory = queryParams.get("taskCategory")?.split(",") || "";
    const surgeryType = queryParams.get("surgeryType")
      ? queryParams.get("surgeryType")?.split(",")
      : [];
    const program = queryParams.get("program")
      ? queryParams.get("program")?.split(",")
      : [];
    const clinic = queryParams.get("clinic")
      ? queryParams.get("clinic")?.split(",")
      : [];
    const assignee = queryParams.get("assignee")
      ? queryParams.get("assignee")?.split(",")
      : [];

    const queryPage = queryParams.get("page") || 1;
    const querySurgeon = queryParams.get("surgeon")?.split(",") || [];

    // Filter and transform procedureTypeData based on surgeryType array
    const transformedSurgeryTypeData = procedureTypeData?.data
      .filter((item) => surgeryType.includes(toSnakeCase(item.name)))
      .map((item) => ({
        ...item,
        value: toSnakeCase(item.name),
      }));

    // Filter and transform procedureTypeData based on surgeryType array
    const transformedTaskcategoryData =
      patientQueueFilterData?.taskCategory?.value
        .filter((item) => taskCategory.includes(item.value))
        .map((item) => ({
          ...item,
          value: item.value,
        }));

    // Filter and transform procedureTypeData based on surgeryType array
    const transformedProgramData = patientQueueFilterData?.program?.value
      .filter((item) => program.includes(toSnakeCase(item.value)))
      .map((item) => ({
        ...item,
        value: toSnakeCase(item.value),
      }));

    // Filter and transform Clinic Data based on surgeryType array
    const transformedClinicData = patientQueueFilterData?.clinic?.value
      .filter((item) => clinic.includes(toSnakeCase(item.value)))
      .map((item) => ({
        ...item,
        value: toSnakeCase(item.value),
      }));

    // Filter and transform procedureTypeData based on surgeryType array
    const transformedSurgeonData = patientQueueFilterData?.surgeon?.value
      .filter((item) => querySurgeon.includes(toSnakeCase(item.value)))
      .map((item) => ({
        ...item,
        value: toSnakeCase(item.value),
      }));

    const assigneeData = userData?.data
      ? userData.data.map((item) => ({
          id: item._id,
          title: item.name,
          value: item._id,
        }))
      : [];

    const transformedAssigneeData = assigneeData
      ?.filter((item) => assignee.includes(item.value))
      ?.map((item) => ({
        ...item,
        value: item.value,
      }));

    // Create an object to hold query parameter values
    const newQueryValue = {
      acuity: acuityQueryValue,
      taskCategory: transformedTaskcategoryData,
      surgeryType: transformedSurgeryTypeData,
      program: transformedProgramData,
      surgeon: transformedSurgeonData,
      clinic: transformedClinicData,
      assignee: transformedAssigneeData,
    };

    // Create a new object to update queriesValue
    const updatedQueriesValue = { ...queriesValue };

    // Iterate over newQueryValue to check each key
    for (const key in newQueryValue) {
      const value = newQueryValue[key];

      // Check if the value is not empty (array with length or non-empty string)
      if (Array.isArray(value) ? value.length > 0 : value) {
        updatedQueriesValue[key] = value;
      }
    }

    // Update queriesValue with non-empty values from newQueryValue
    setQueriesValue(updatedQueriesValue);
    setPage(Number.parseInt(queryPage) || 1);

    localStorage.setItem(
      "queriesValue",
      updatedQueriesValue ? JSON.stringify(updatedQueriesValue) : {},
    );
    localStorage.setItem(
      "taskCategory",
      transformedTaskcategoryData
        ? JSON.stringify(transformedTaskcategoryData)
        : [],
    );
    localStorage.setItem(
      "surgeryType",
      transformedSurgeryTypeData
        ? JSON.stringify(transformedSurgeryTypeData)
        : [],
    );
    localStorage.setItem(
      "program",
      transformedProgramData ? JSON.stringify(transformedProgramData) : [],
    );
    localStorage.setItem(
      "clinic",
      transformedClinicData ? JSON.stringify(transformedClinicData) : [],
    );
    localStorage.setItem("page", queryPage || 1);
    localStorage.setItem(
      "surgeon",
      transformedSurgeonData ? JSON.stringify(transformedSurgeonData) : [],
    );
    localStorage.setItem(
      "assignee",
      transformedAssigneeData ? JSON.stringify(transformedAssigneeData) : [],
    );
  }, [procedureTypeData, userData]);

  // Loader for both data
  if (isLoading || isQueueLoading || isPhysicianLoading)
    return (
      <>
        <CircularLoader />
      </>
    );

  // If Token is expire it navigate to Login page
  // !isAuthenticated && navigate("/")
  return (
    <Grid container spacing={2} sx={{ background: "#fff" }}>
      <Grid
        item
        xs={2}
        sx={{
          borderRight: "1px solid #DFE1E6",
          // boxShadow: "1px 5px 1px 1px rgba(52, 58, 64, 0.2)",
        }}
      >
        <FilterSidebar
          data={filterData}
          queriesValue={queriesValue}
          setQueriesValue={setQueriesValue}
          setSelectedValue={setSelectedValue}
          setPage={setPage}
          page={page}
          setSortTaskCategoryOrder={setSortTaskCategoryOrder}
          setSortPhysicianNameOrder={setSortPhysicianNameOrder}
          setSortProcedureTypeOrder={setSortProcedureTypeOrder}
          setSortQueueTypeOrder={setSortQueueTypeOrder}
          setSortSocialNeedsOrder={setSortSocialNeedsOrder}
          setSortContactTimeOrder={setSortContactTimeOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          setSortPatientOrder={setSortPatientOrder}
        />
      </Grid>
      <Grid item xs={10}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "1.5rem 1.5rem 1.5rem 0.5rem",
          }}
        >
          {/* <TotalCount {...{ data: data || [], isError }} /> */}
          {showAnalytics && (
            <UpperAnalytics
              data={data?.metrics || {}}
              backendData={filterData}
              queriesValue={queriesValue}
              setQueriesValue={setQueriesValue}
            />
          )}
          <PatientQueue
            queueData={data || null}
            queries={queriesValue}
            setQueries={setQueriesValue}
            sortField={sortField}
            sortOrder={sortOrder}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            selectedPatientIds={selectedPatientIds}
            setSelectedPatientIds={setSelectedPatientIds}
            refetchQueueAgain={refetchQueueAgain}
            page={page}
            setPage={setPage}
            handleSorting={handleSorting}
            sortPatientOrder={sortPatientOrder}
            sortAcuityOrder={sortAcuityOrder}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            sortTaskCategoryOrder={sortTaskCategoryOrder}
            sortPhysicianNameOrder={sortPhysicianNameOrder}
            sortProcedureTypeOrder={sortProcedureTypeOrder}
            sortQueueTypeOrder={sortQueueTypeOrder}
            sortSocialNeedsOrder={sortSocialNeedsOrder}
            sortContactTimeOrder={sortContactTimeOrder}
            filterData={filterData}
          />
          <InactivityCheck />
        </div>
      </Grid>
    </Grid>
  );
}
