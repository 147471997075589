import {
  Paper,
  Chip,
  Typography,
  Box,
  Grid,
  Tooltip,
  Badge,
  IconButton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useChatValues } from "../../../contexts/chatValue";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addInParams } from "../../../utils";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ShieldIcon from "@mui/icons-material/Shield";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import BlockIcon from "@mui/icons-material/Block";
import { PaginationBar } from "../../../Components";
import moment from "moment";
import { Conversation } from "../../../assets/svgs/PatientProfileTabsSvgs/Conversation.js";
import { InfoOutlined, RemoveRedEye, Replay } from "@mui/icons-material";
import CareProgram from "../../../Components/TableFields/CareProgram.js";
import { PatientProfileDrawer } from "../../../Components/PatientProfile/PatientProfileDrawer.js";
import { toast } from "react-toastify";
import { useResendConsentMutation } from "../../../store/chatSlice.js";
import { ConfirmationModal } from "../../../Components/MessageLayout/ConfirmationModal.js";

export function PatientListingCard({
  value,
  queueData,
  setSelectedPatientIds,
  page,
  setPage,
  isV2Profile,
  patientListRefetch,
}) {
  const { setChatMessages } = useChatValues();
  const prevDataRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState("");
  const [patientQueues, setPatientQueues] = useState([]);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [openPatientProfileDrawer, setOpenPatientProfileDrawer] =
    useState(false);
  const [resendConsent] = useResendConsentMutation();
  const [openResend, setOpenResend] = useState(false);
  const [consentValues, setConsentValues] = useState({ id: "", status: "" });
  const handleResend = (id, status) => {
    setConsentValues({ id, status });
    setOpenResend(true);
  };

  const iconMap = {
    food: <RestaurantIcon />,
    transportation: <DirectionsCarIcon />,
    housing: <HouseOutlinedIcon />,
    safety: <ShieldIcon />,
    utility: <TipsAndUpdatesIcon />,
    financial: <PaymentsIcon />,
    urgent: <DirectionsRunIcon />,
  };

  const handleChangePage = (event, newPage) => {
    if (isV2Profile) {
      localStorage.setItem("page3", newPage);
      setPage(newPage);
      addInParams("page", newPage);
    } else {
      setSelectedPatientIds([]);
      localStorage.setItem("page", newPage);
      setPage(newPage);
      addInParams("page", newPage);
    }
  };

  const handleDataResend = async () => {
    if (consentValues?.status === "active") {
      const response = await resendConsent(consentValues?.id);
      if (response?.data?.message) {
        toast.success("The SMS consent has been resent.");
        setOpenResend(false);
        setConsentValues({ id: "", status: "" });
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      toast.error("The patient status is currently inactive.");
    }
  };

  const redirectToEditPage = (id, name) => {
    // localStorage.setItem('pageName2', JSON.stringify(name))
    navigate(`/profile/${id}`, { state: { action: "read-only", id } });
  };

  const calculateAge = (dob) => {
    if (dob) {
      // Check if the date is in ISO 8601 format
      const birthDate = dob.includes("T")
        ? new Date(dob)
        : new Date(`${dob}T00:00:00.000Z`);
      const today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}Y`;
    } else {
      return `N/A`;
    }
  };

  const getColorStyles = (val, onlyColor = false) => {
    const colors = {
      "High Risk": "#DC362E",
      "High risk": "#DC362E",
      high: "#DC362E",
      no: "#DC362E",
      "Moderate Risk": "#FFB400",
      "Rising Risk": "#FFB400",
      "Rising risk": "#FFB400",
      NO_RESPONSE: "#FFB400",
      "Low Risk": "#63A02C",
      Healthy: "#63A02C",
      yes: "#63A02C",
    };

    const baseColor = colors[val] || "#000000"; // Default to black if risk is not found
    if (onlyColor) {
      return baseColor;
    } // Return only the color if required
    const hexToRgba = (hex, alpha) => {
      const [r, g, b] = hex
        .replace(/^#/, "")
        .match(/.{2}/g)
        .map((x) => Number.parseInt(x, 16));
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    return {
      marginRight: "5px",
      color: baseColor, // Text color
      backgroundColor: hexToRgba(baseColor, 0.1), // 20% opacity for background color
    };
  };

  const togglePatientProfileDrawer = (open) => () => {
    addInParams("patientId", open?.patient?._id);
    addInParams("tab", open?.isJourney ? "6" : open?.value ? "0" : null);

    setChatMessages([]);
    setOpenPatientProfileDrawer(open);
  };

  useEffect(() => {
    if (queueData && isV2Profile) {
      setTotalPages(queueData?.totalPages);
      setPatientQueues(queueData?.data);
    } else if (queueData) {
      if (queueData?.patientQueues !== prevDataRef?.current?.length) {
        prevDataRef.current = queueData?.patientQueues;

        setTotalPages(queueData?.totalPages);
        setPatientQueues(queueData?.patientQueues);
      }
    }
  }, [queueData]);

  useEffect(() => {
    if (page > queueData?.totalPages) {
      localStorage.setItem("page", 1);
      setPage(1);
    }
  }, [queueData]);

  useEffect(() => {
    if (
      queryParams.get("tab") &&
      queryParams.get("patientId") &&
      !hasRunOnce &&
      patientQueues?.find((item) => item?._id == queryParams.get("patientId"))
    ) {
      setChatMessages([]);
      setOpenPatientProfileDrawer({
        value: true,
        patient: patientQueues?.find(
          (item) => item?._id == queryParams.get("patientId"),
        ),
      });
      setHasRunOnce(true);
    }
  }, [patientQueues]);

  return (
    <>
      {patientQueues &&
        patientQueues?.map((item, index) => (
          <Paper
            elevation={0}
            sx={{
              px: 3,
              py: 2,
              my: 3,
              position: "relative", // Ensure the Paper is a positioning context
              borderRadius: "8px",
              border: "1px solid #DEDEDE",
              transition: "all 0.3s ease-in-out", // Smooth transition
              "&:hover": {
                backgroundColor: "#f5f5f5", // Change background color on hover
                cursor: "pointer", // Change cursor to pointer
              },
            }}
            key={index}
            onClick={togglePatientProfileDrawer({ value: true, patient: item })}
          >
            {/* Main Grid of Card */}
            <Grid container>
              <Grid item sm={12} padding={0.5}>
                {/* First Row of Card */}
                <Grid container spacing={2}>
                  {/* Grid containing Patient Info */}
                  <Grid item sm={2.5}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "500", fontSize: 16 }}
                          >
                            {item?.firstName} {item?.lastName}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ fontWeight: "normal", fontSize: 14 }}
                          >
                            {`${calculateAge(item?.birthday)}, ${
                              item?.gender
                                ? item.gender.charAt(0).toUpperCase() +
                                  item.gender.slice(1)
                                : ""
                            } | DOB: ${item?.birthday ? moment(item?.birthday).format("MM-DD-YYYY") : "--/--/----"}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {/* Grid containing Task Category Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Care Pathway
                      </Typography>
                      <CareProgram
                        program={item.carePathways}
                        isClickable={true}
                        togglePatientProfileDrawer={togglePatientProfileDrawer}
                        patient={item}
                      />
                    </Box>
                  </Grid>
                  {/* Grid containing Physician Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Surgeon
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500", fontSize: 16 }}
                      >
                        {item?.surgeonName ? item?.surgeonName : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Grid containing Procedure Info */}
                  <Grid item sm={4.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Procedure Type / Date
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500", fontSize: 16 }}
                      >
                        {`${item?.surgeryType || "N/A"} | `}
                        <span style={{ whiteSpace: "nowrap" }}>
                          {`${
                            item?.surgeryDate
                              ? moment(item?.surgeryDate).format("MM-DD-YYYY")
                              : "--/--/----"
                          }`}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                {/* Second Row of Card */}
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {/* Grid containing Acuity Info */}
                  <Grid item sm={2.5}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Risk Category
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={
                            item.riskCategory.score
                              ? item?.riskCategory?.score
                                  ?.charAt(0)
                                  ?.toUpperCase() +
                                item?.riskCategory?.score?.slice(1)
                              : "N/A"
                          }
                          size="small"
                          sx={{
                            ...getColorStyles(
                              item?.riskCategory?.score
                                ? item?.riskCategory?.score
                                : "N/A",
                            ),
                            height: "20px",
                            fontSize: "0.75rem",
                            borderRadius: "4px",
                            fontWeight: "500",
                            maxWidth: "fit-content",
                          }}
                        />
                        {item?.riskCategory?.mfiValue >= 0 &&
                          item?.riskCategory?.score && (
                            <Tooltip
                              title={`MFI Score: ${item?.riskCategory?.mfiValue}`}
                            >
                              <InfoOutlined
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  color: getColorStyles(
                                    item?.riskCategory?.score
                                      ? item?.riskCategory?.score
                                      : "N/A",
                                    true,
                                  ),
                                }}
                              />
                            </Tooltip>
                          )}
                      </Box>
                    </Box>
                  </Grid>

                  {/* Grid containing Social Needs Info */}
                  <Grid item sm={2.5}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Social Needs
                      </Typography>

                      <div
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(6, 1fr)",
                            gap: "4px",
                            justifyContent: "start",
                            alignContent: "center",
                            width: "50%",
                          }}
                        >
                          {(item?.socialNeeds || item?.socialRisks) &&
                          (item?.socialNeeds?.length > 0 ||
                            item?.socialRisks?.length > 0) ? (
                            [
                              ...(item?.socialRisks || []),
                              ...(item?.socialNeeds || []),
                            ].map((icon, index) => {
                              const isSocialNeed =
                                item?.socialNeeds?.includes(icon);
                              const color = isSocialNeed
                                ? "#FFB546"
                                : "#EF4444";
                              const IconElement = iconMap[icon] || (
                                <BlockIcon />
                              );
                              return (
                                <Tooltip
                                  key={index}
                                  title={
                                    icon.charAt(0).toUpperCase() + icon.slice(1)
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex", // Flexbox layout
                                      alignItems: "center", // Vertical centering
                                      justifyContent: "center", // Horizontal centering
                                      cursor: "pointer",
                                    }}
                                  >
                                    {React.cloneElement(IconElement, {
                                      sx: { color, fontSize: 16 },
                                    })}
                                  </div>
                                </Tooltip>
                              );
                            })
                          ) : (
                            <div
                              style={{
                                gridColumn: "1 / span 3", // Span across all columns
                              }}
                            >
                              N/A
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>

                  {/* Grid containing Queue Type Info */}
                  <Grid item sm={2.5}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        SMS Consent
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          label={
                            item?.consentResponse === "NO_RESPONSE"
                              ? "No Response"
                              : item?.consentResponse?.charAt(0).toUpperCase() +
                                item?.consentResponse?.slice(1)
                          }
                          size="small"
                          sx={{
                            ...getColorStyles(
                              item?.consentResponse
                                ? item?.consentResponse
                                : "N/A",
                            ),
                            height: "20px",
                            fontSize: "0.75rem",
                            borderRadius: "4px",
                            fontWeight: "500",
                            maxWidth: "fit-content",
                          }}
                        />
                        {item?.consentResponse !== "yes" &&
                          item?.status === "active" && (
                            <Tooltip title="Resend SMS Consent">
                              <Replay
                                sx={{ width: "20px", height: "20px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleResend(item?._id, item?.status);
                                }}
                              />
                            </Tooltip>
                          )}
                      </Box>
                    </Box>
                  </Grid>
                  {/* Grid containing Creation Info */}
                  <Grid item sm={1.25}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Enrollment Date
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500", fontSize: 16 }}
                      >
                        {item?.createdAt
                          ? moment(item?.createdAt).format("MM-DD-YYYY")
                          : "--/--/----"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Grid containing Assignee Info */}
                  <Grid item sm={1.25}>
                    <Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ fontWeight: "normal", fontSize: 14 }}
                      >
                        Self Enrolled
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "500", fontSize: 16 }}
                      >
                        {item.selfEnrolled ? "Yes" : "No"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* Grid containing Actions Info */}
                  <Grid item sm={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        sx={{
                          border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                          borderRadius: "8px", // Optional: Make the border circular if needed
                          padding: "4px", // Adjust padding to maintain proper spacing
                          display: "inline-flex", // Center the content inside the border
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "5px",
                          marginTop: "5px",
                        }}
                        onClick={togglePatientProfileDrawer({
                          value: true,
                          patient: item,
                        })}
                      >
                        <Tooltip title="Message">
                          <Badge>
                            <Conversation color={"black"} size={20} />
                          </Badge>
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        sx={{
                          border: "1px solid rgb(0, 0, 0, 0.12)", // Add an 8px border
                          borderRadius: "8px", // Optional: Make the border circular if needed
                          padding: "4px", // Adjust padding to maintain proper spacing
                          display: "inline-flex", // Center the content inside the border
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "5px",
                        }}
                        onClick={() => {
                          redirectToEditPage(item?._id);
                        }}
                      >
                        <Tooltip title="Go to Profile">
                          <Badge>
                            <RemoveRedEye sx={{ color: "black" }} size={20} />
                          </Badge>
                        </Tooltip>
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ))}

      <PaginationBar
        {...{ page, count: totalPages, handleChange: handleChangePage }}
      />
      {openPatientProfileDrawer?.value && (
        <PatientProfileDrawer
          state={openPatientProfileDrawer.value}
          toggleDrawer={togglePatientProfileDrawer}
          name={`${openPatientProfileDrawer?.patient?.firstName} ${openPatientProfileDrawer?.patient?.lastName}`}
          user={user}
          path={openPatientProfileDrawer?.patient?.path}
          patient={openPatientProfileDrawer?.patient}
          acuity={openPatientProfileDrawer?.patient?.acuity}
          value={value}
          id={openPatientProfileDrawer?.patient?._id}
          status={openPatientProfileDrawer?.patient?.status}
          consent={openPatientProfileDrawer?.patient?.consentResponse}
          isJourney={openPatientProfileDrawer?.isJourney}
          patientListRefetch={patientListRefetch}
        />
      )}
      <ConfirmationModal
        open={openResend}
        setOpen={setOpenResend}
        txt="Are you sure you want to resend the SMS consent?"
        handle={handleDataResend}
      />
    </>
  );
}
