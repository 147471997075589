import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    width: '400px',
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  '& .MuiTypography-root': {
    fontSize: '18px',
    fontWeight: 500
  }
});

export const DeleteConfirmationDialog = ({ open, filter, onClose, onConfirm }) => (
  <StyledDialog 
    open={open} 
    onClose={onClose}
    maxWidth="xs"
  >
    <StyledDialogTitle>
      <Typography>Delete Filter</Typography>
      <IconButton 
        edge="end" 
        onClick={onClose}
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </StyledDialogTitle>

    <DialogContent sx={{ pt: 2, pb: 3 }}>
      <Typography variant="body1" sx={{ fontSize: '14px' }}>
        Are you sure you want to delete "{filter?.name}"? This action cannot be undone.
      </Typography>
      
      <Box sx={{ 
        mt: 3, 
        display: 'flex', 
        justifyContent: 'flex-end',
        gap: 1
      }}>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            minWidth: '80px',
            borderColor: '#E5E7EB',
            color: '#6B7280',
            '&:hover': {
              borderColor: '#D1D5DB',
              bgcolor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={() => onConfirm(filter)}
          variant="contained"
          sx={{
            textTransform: 'none',
            minWidth: '80px',
            bgcolor: '#EF4444',
            '&:hover': {
              bgcolor: '#DC2626'
            }
          }}
        >
          Delete
        </Button>
      </Box>
    </DialogContent>
  </StyledDialog>
);