import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  Box,
  IconButton,
  styled,
  ListItemIcon,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';       
import PublicIcon from '@mui/icons-material/Public'; 

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    width: '400px',
  }
}));

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  '& .MuiTypography-root': {
    fontSize: '18px',
    fontWeight: 500
  }
});

const SaveFilterModal = ({ open, onClose, onSave, onEdit, mode, filterToEdit, loading }) => {
  const [filterName, setFilterName] = useState('');
  const [visibility, setVisibility] = useState('private');

  useEffect(() => {
    if (filterToEdit && mode === 'edit') {
      setFilterName(filterToEdit.name)
      setVisibility(filterToEdit.visibility)
    }
  }, [mode, filterToEdit])

  const handleSave = () => {
    onSave({
      name: filterName,
      visibility: visibility
    });
    setFilterName('');
    setVisibility('private');
    onClose();
  };

  const handleEdit = () => {
    onEdit({
      name: filterName,
      visibility: visibility,
      filterId: filterToEdit._id,
    });
    setFilterName('');
    setVisibility('private');
    onClose();
  };

  const renderValue = (selected) => {
    return selected === 'private' ? 'Private (default)' : 'Public';
  };

  return (
    <StyledDialog
      open={open}
      onClose={() => {
        setFilterName('')
        setVisibility('private')
        onClose()
      }}
      maxWidth="sm"
    >
      <StyledDialogTitle>
        <Typography>{mode === 'edit' ? 'Edit Filter' : 'Create Filter'}</Typography>
        <IconButton 
          edge="end" 
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>

      <DialogContent sx={{ pt: 2, pb: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography 
            sx={{ 
              mb: 1, 
              fontSize: '14px',
              fontWeight: 500 
            }}
          >
            Filter Name
          </Typography>
          <TextField
            fullWidth
            placeholder="Enter filter name"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
              }
            }}
          />
        </Box>

        <Box>
          <Typography 
            sx={{ 
              mb: 1, 
              fontSize: '14px',
              fontWeight: 500 
            }}
          >
            Visibility
          </Typography>
          <Select
            fullWidth
            value={visibility}
            renderValue={renderValue}
            onChange={(e) => setVisibility(e.target.value)}
            size="small"
            sx={{
              borderRadius: '4px',
            }}
          >
            <MenuItem value="private">
              <ListItemIcon>            
                <LockIcon fontSize="small" />
              </ListItemIcon>
              Private (default)
            </MenuItem>
            <MenuItem value="public">
              <ListItemIcon>             
                <PublicIcon fontSize="small" />
              </ListItemIcon>
              Public
            </MenuItem>
          </Select>
        </Box>

        <Box sx={{ 
          mt: 3, 
          display: 'flex', 
          justifyContent: 'flex-end'
        }}>
          <Button
            variant="contained"
            onClick={mode === 'create' ? handleSave : handleEdit}
            disabled={!filterName.trim() || loading}
            sx={{
              textTransform: 'none',
              minWidth: '80px',
              bgcolor: '#6366F1',
              '&:hover': {
                bgcolor: '#4F46E5'
              },
              '&.Mui-disabled': {
                bgcolor: '#E5E7EB',
                color: '#9CA3AF'
              }
            }}
            endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default SaveFilterModal;