import React, { useState } from "react";
import { Grid } from "@mui/material";
import {
  useGetPatientsQuery,
  useGetCareProgramPatientsQuery,
} from "../../../store/patientsSlice";
import { useGetPhysiciansOptionsQuery } from "../../../store/physiciansSlice";
import { useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useIsMobile } from "../../../contexts/isMobile";
import { useIsHovered } from "../../../contexts/isHovered";
import { InactivityCheck } from "../../../Components/InactivityCheck";
import { patientProfileFilterData } from "../../../FilterUtils";
import { UpperAnalytics } from "../UpperAnalytics";
import { PatientQueue } from "../../Dashboard/PatientQueue";
import { FilterSidebar } from "../FilterSidebar";
import { useGetCarePathwayQuery } from "../../../store/carepathwaysSlice";
import { useChildNode } from "../../../contexts/addChildNode";
import { addInParams, toSnakeCase } from "../../../utils";
import { useGetProceduresOptionsQuery } from "../../../store/proceduresSlice";
import { CircularLoader } from "../../../Components";

const dummyAnalyticData = {
  surgeonPatientCounts: [
    {
      surgeonBreakdown: [
        {
          label: null,
          count: 65,
        },
        {
          label: "Dr. Rahman, Omar",
          count: 44,
        },
        {
          label: "Dr. Rahman 1, Omar",
          count: 9,
        },
      ],
      totalCount: 118,
    },
  ],
  outstandingInquires: [
    {
      categoryBreakdown: [
        {
          label: "New Form Submission",
          count: 25,
        },
        {
          label: "New Media Upload",
          count: 15,
        },
        {
          label: "New Task Assignment",
          count: 9,
        },
        {
          label: "Urgent/Emergent Clinician Review",
          count: 5,
        },
        {
          label: "Appointment Request",
          count: 3,
        },
        {
          label: "Appointment Scheduling",
          count: 2,
        },
        {
          label: "Clinical Monitoring & Guidance",
          count: 2,
        },
        {
          label: "New Care Ally Message",
          count: 2,
        },
        {
          label: "Appointment Request 1",
          count: 1,
        },
      ],
      totalCount: 64,
    },
  ],
  activeTriageNeeds: [
    {
      acuityBreakdown: [
        {
          label: "medium",
          count: 54,
        },
        {
          label: "N/A",
          count: 35,
        },
        {
          label: "low",
          count: 15,
        },
        {
          label: "high",
          count: 14,
        },
      ],
      totalCount: 118,
    },
  ],
};

const columns = [
  { id: "patientInfo", label: "Patient Information", minWidth: 250 },
  // {
  //   id: "cptCode",
  //   label: "CPT Code",
  //   minWidth: 170,
  //   align: "right",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "pcp",
  //   label: "PCP",
  //   minWidth: 170,
  //   align: "right",
  //   // format: (value) => value.toLocaleString("en-US"),
  // },
  {
    id: "surgeonName",
    label: "Surgeon Name",
    minWidth: 70,
    align: "right",
  },

  {
    id: "procedureType",
    label: "Procedure Type/Procedure Date",
    minWidth: 70,
    align: "right",
  },

  {
    id: "carePathways",
    label: "Care Pathways",
    minWidth: 10,
    align: "right",
  },
  {
    id: "socialNeeds",
    label: "Social Needs",
    minWidth: 10,
    align: "right",
  },
  {
    id: "riskCategory",
    label: "Risk Category",
  },
  {
    id: "socialRiskLevel",
    label: "Social Risk Level",
  },
  {
    id: "consent",
    label: "SMS Consent",
  },
  {
    id: "selfEnrolled",
    label: "Self Enrolled",
  },
  // {
  //   id: "status",
  //   label: "Patient Status",
  // },
  {
    id: "action",
    label: "Action",
  },
];

export function PatientTable() {
  // Using use Params
  const { programValue } = useParams();
  const { debouncedPatientValue, showPatientsAnalytics } = useChildNode();
  const location = useLocation();

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);

  const isMobile = useIsMobile();
  const { hovered } = useIsHovered();
  const [pageName, setPageName] = useState(
    localStorage.getItem("pageName2")
      ? JSON.parse(localStorage.getItem("pageName2"))
      : "patient",
  );
  const [page, setPage] = useState(
    queryParams.get("page")
      ? Number.parseInt(queryParams.get("page"))
      : localStorage.getItem("page3")
        ? Number.parseInt(localStorage.getItem("page3"))
        : 1,
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const searchAbleFields = ["email", "name", "phone"];
  const [selectedFields, setSelectedFields] = useState([...searchAbleFields]);
  const [queriesValue, setQueriesValue] = useState(
    localStorage.getItem("queriesValue2")
      ? JSON.parse(localStorage.getItem("queriesValue2"))
      : {},
  );
  const [filterData, setFilterData] = useState({});

  const [sortField, setSortField] = useState(
    localStorage.getItem("sortField3")
      ? localStorage.getItem("sortField3")
      : "createdAt",
  );
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem("sortOrder3")
      ? localStorage.getItem("sortOrder3")
      : "desc",
  );
  const [sortPatientOrder, setSortPatientOrder] = useState(
    localStorage.getItem("sortPatientOrder3")
      ? JSON.parse(localStorage.getItem("sortPatientOrder3"))
      : { field: "patientName", sort: "asc" },
  );
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("selectedValue")
      ? localStorage.getItem("selectedValue")
      : " asc",
  );

  const handleFieldSelect = (option, checked) => {
    if (checked) {
      setSelectedFields([...selectedFields, option]);
    } else {
      setSelectedFields(selectedFields.filter((o) => o !== option));
    }
  };
  const { user } = useSelector((state) => state.user);
  const queries = {};
  // for (const field of selectedFields) {
  //   queries[field] = debouncedPatientValue;
  // }

  queries["email"] = debouncedPatientValue;
  queries["name"] = debouncedPatientValue;
  queries["phone"] = debouncedPatientValue;
  queries["program"] = queriesValue?.program?.map((item) => item.id) || [];
  queries["surgeryTypes"] =
    queriesValue?.surgeryTypes?.map((item) => item.id) || [];
  queries["surgeonNames"] =
    queriesValue?.surgeonNames?.map((item) => item.id) || [];
  queries["status"] = queriesValue?.status || "active";
  if (queriesValue?.selfEnrolled !== undefined) {
    queries["selfEnrolled"] = queriesValue.selfEnrolled;
  }
  queries["consentApproved"] = queriesValue?.consentApproved || "";

  const { data, isLoading, isError, isSuccess, refetch } = useGetPatientsQuery(
    {
      userID: user.id,
      page,
      tags: selectedTags,
      sortField,
      sortOrder,
      ...queries,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: physicianOptions, isLoading: isPhysicianLoading } =
    useGetPhysiciansOptionsQuery();

  const { data: procedureTypeData } = useGetProceduresOptionsQuery();

  console.log("DATA:", physicianOptions);

  // For Care Program Patient
  const {
    data: careProgramData,
    isLoading: careProgramLoading,
    isError: careProgramError,
    isSuccess: careProgramSuccess,
  } = useGetCareProgramPatientsQuery(
    {
      userID: user.id,
      page,
      programValue,
      tags: selectedTags,
      ...queries,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: carePathwayData } = useGetCarePathwayQuery();

  const refetchPatientAgain = () => {
    refetch();
  };

  const handleSorting = (field, isCreatedAt) => {
    if (sortPatientOrder.field === "patientName" && field === "patientInfo") {
      setSortPatientOrder(
        sortPatientOrder.sort === "asc"
          ? { field: "patientName", sort: "desc" }
          : { field: "patientName", sort: "asc" },
      );
      setSortField("patientName");
      setSortOrder(sortPatientOrder.sort === "asc" ? "desc" : "asc");
      localStorage.setItem(
        "sortPatientOrder3",
        JSON.stringify(
          sortPatientOrder.sort === "asc"
            ? { field: "patientName", sort: "desc" }
            : { field: "patientName", sort: "asc" },
        ),
      );
      addInParams("sortField", "patientName");
      addInParams(
        "sortOrder",
        sortPatientOrder.sort === "asc" ? "desc" : "asc",
      );
    }

    localStorage.setItem(
      "sortField3",
      isCreatedAt
        ? "contactTime"
        : field === "patientInfo"
          ? "patientName"
          : field,
    );
    localStorage.setItem("sortOrder3", sortOrder === "asc" ? "desc" : "asc");
  };

  React.useEffect(() => {
    if (page > data?.totalPages) {
      localStorage.setItem("page3", 1);
      setPage(1);
    }
  }, [data]);

  React.useEffect(() => {
    localStorage?.removeItem("createCareAllyForm");
    localStorage?.removeItem("stepperValue");
    localStorage?.removeItem("patientForm");
    localStorage?.removeItem("assignJourneyForm");
    localStorage?.removeItem("assignSurgeryForm");
  }, []);

  React.useEffect(() => {
    if (carePathwayData?.data) {
      const program = {
        value: carePathwayData?.data
          ? Object.entries(carePathwayData.data).map(([key, value]) => ({
              id: value,
              title: key,
              value: value,
            }))
          : [],
      };

      const surgeonName = {
        value:
          physicianOptions?.length !== 0
            ? physicianOptions?.map((item) => ({
                id: item?._id,
                title: item?.name,
                value: toSnakeCase(item?.name),
              }))
            : [],
      };

      const surgeryType = {
        value: [
          {
            id: "no_procedure",
            title: "N/A",
            value: "no_procedure",
          },
          ...(procedureTypeData?.data?.map((item) => ({
            id: item._id,
            title: item.name,
            value: toSnakeCase(item.name),
          })) || []),
        ],
      };

      const newPatientProfileFilterData = {
        ...patientProfileFilterData,
        program,
        surgeryType,
        surgeonName,
      };

      setFilterData(newPatientProfileFilterData);
    } else {
      setFilterData(patientProfileFilterData);
    }
  }, [carePathwayData, physicianOptions, procedureTypeData]);

  React.useEffect(() => {
    if (!queryParams.get("page") || !queryParams.get("status")) {
      addInParams(
        "page",
        localStorage.getItem("page3")
          ? Number.parseInt(localStorage.getItem("page3"))
          : 1,
      );
      addInParams("status", "active");
    }
  }, []);

  React.useEffect(() => {
    // Parse each query parameter, including surgeryType as an array
    const sortFieldQueryValue = queryParams.get("sortField")
      ? queryParams.get("sortField")
      : localStorage.getItem("sortField3")
        ? localStorage.getItem("sortField3")
        : "createdAt";
    const sortOrderQueryValue = queryParams.get("sortOrder")
      ? queryParams.get("sortOrder")
      : localStorage.getItem("sortOrder3")
        ? localStorage.getItem("sortOrder3")
        : "desc";
    const statusQueryValue = queryParams.get("status") || "active";
    const consentApprovedQueryValue = queryParams.get("consentApproved") || "";
    const selfEnrolledQueryValue = queryParams.get("selfEnrolled") || "";
    const program = queryParams.get("program")
      ? queryParams.get("program")?.split(",")
      : [];
    const surgeryTypes = queryParams.get("surgeryTypes")
      ? queryParams.get("surgeryTypes")?.split(",")
      : [];
    const surgeonNames = queryParams.get("surgeonNames")
      ? queryParams.get("surgeonNames")?.split(",")
      : [];

    const newProgramData = carePathwayData?.data
      ? Object.entries(carePathwayData.data).map(([key, value]) => ({
          id: value,
          title: key,
          value: value,
        }))
      : [];

    const newSurgeonNameData =
      physicianOptions?.length !== 0
        ? physicianOptions?.map((item) => ({
            id: item?._id,
            title: item?.name,
            value: toSnakeCase(item?.name),
          }))
        : [];

    const newSurgeryTypeData = procedureTypeData?.data?.map((item) => ({
      id: item._id,
      title: item.name,
      value: toSnakeCase(item.name),
    }));

    const transformedSurgeryTypesData = newSurgeryTypeData
      ?.filter((item) => surgeryTypes.includes(item.id))
      ?.map((item) => ({
        ...item,
        value: item.value,
      }));

    const transformedSurgeonNamesData = newSurgeonNameData
      ?.filter((item) => surgeonNames.includes(item.id))
      ?.map((item) => ({
        ...item,
        value: item.value,
      }));

    // Filter and transform procedureTypeData based on surgeryType array
    const transformedProgramData = newProgramData
      ?.filter((item) => program.includes(item.id))
      ?.map((item) => ({
        ...item,
        value: toSnakeCase(item.value),
      }));

    // Create an object to hold query parameter values
    const newQueryValue = {
      status: statusQueryValue,
      consentApproved: consentApprovedQueryValue,
      selfEnrolled: selfEnrolledQueryValue,
      program: transformedProgramData,
      surgeryTypes: transformedSurgeryTypesData,
      surgeonNames: transformedSurgeonNamesData,
      // clinic: transformedClinicData,
    };

    // Create a new object to update queriesValue
    const updatedQueriesValue = { ...queriesValue };

    // Iterate over newQueryValue to check each key
    for (const key in newQueryValue) {
      const value = newQueryValue[key];

      // Check if the value is not empty (array with length or non-empty string)
      if (Array.isArray(value) ? value.length > 0 : value) {
        updatedQueriesValue[key] = value;
      }
    }

    // Update queriesValue with non-empty values from newQueryValue
    setQueriesValue(updatedQueriesValue);
    // setPage(parseInt(queryPage) || 1)

    setSortOrder(sortOrderQueryValue);
    setSortField(sortFieldQueryValue);
  }, [
    procedureTypeData,
    patientProfileFilterData,
    procedureTypeData,
    carePathwayData,
    physicianOptions,
  ]);

  if (isLoading)
    return (
      <>
        <CircularLoader />
      </>
    );

  // If Token is expire it navigate to Login page
  return (
    <>
      {/* <Paper
      sx={{
        width: "95%",
        boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
        border: "1px solid #D0EAE1",
        margin: '45px',
        paddingX: '12px' 
      }}
      elevation={0}
    >
    {programValue !== undefined ? (
    <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
      <TableHeader
        {...{
          selectedTags,
          setSelectedTags,
          searchTerm,
          setSearchTerm,
          setQueriesValue,
          queriesValue,
          options: searchAbleFields,
          selectedOptions: selectedFields,
          handleOptionSelect: handleFieldSelect,
        }}
      />
       <Table
          {...{
            selectedTags,
            setSelectedTags,
            page,
            setPage,
            data: careProgramData,
            isLoading: careProgramLoading,
            isError: careProgramError,
            isSuccess: careProgramSuccess,
            refetchPatientAgain
          }}
        />
    </div>
    ):(
      <PatientTableUI {...{
        selectedTags,
        setSelectedTags,
        searchTerm,
        setSearchTerm,
        setQueriesValue,
        queriesValue,
        searchAbleFields,
        selectedFields,
        handleFieldSelect,
        page,
        setPage,
        data,
        isLoading,
        isError,
        isSuccess,
        refetchPatientAgain,
        pageName
      }} />
    )}
    </Paper> */}

      <Grid container spacing={2} sx={{ background: "#fff" }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: "1px solid #DFE1E6",
            // boxShadow: "1px 5px 1px 1px rgba(52, 58, 64, 0.2)",
          }}
        >
          <FilterSidebar
            data={filterData}
            queriesValue={queriesValue}
            setQueriesValue={setQueriesValue}
            setPage={setPage}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSortPatientOrder={setSortPatientOrder}
            setSelectedValue={setSelectedValue}
          />
        </Grid>
        <Grid item xs={10}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              padding: "1.5rem 1.5rem 1.5rem 0.5rem",
            }}
          >
            {showPatientsAnalytics && (
              <UpperAnalytics
                data={data?.metrics}
                backendData={filterData}
                queriesValue={queriesValue}
                setQueriesValue={setQueriesValue}
              />
            )}
            <PatientQueue
              isV2Profile={true}
              column={columns}
              queueData={data || null}
              queries={queriesValue}
              setQueries={setQueriesValue}
              page={page}
              setPage={setPage}
              handleSorting={handleSorting}
              sortPatientOrder={sortPatientOrder}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              sortField={sortField}
              setSortField={setSortField}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              patientListRefetch={refetch}
            />
            <InactivityCheck />
          </div>
        </Grid>
      </Grid>
      <InactivityCheck />
    </>
  );
}
