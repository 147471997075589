import { Box, Grid, Paper, Typography } from "@mui/material";
import { useIsMobile } from "../../../contexts/isMobile";
import { TotalPatientsSvg } from "../../../assets/svgs/TotalPatients";

const StatCard = ({ title, value, icon, sx = {} }) => (
  <Paper
    sx={{
      background: "#fff",
      padding: "12px",
      width: "100%",
      height: 183,
      borderRadius: "4px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.07)",
    }}
  >
    <Box>
      <Typography sx={{ fontSize: "18px", color: "#4B4D4F", fontWeight: 400 }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: "26px", fontWeight: 600, color: "#05080B" }}>
        {value}
      </Typography>
    </Box>
    {icon && <Box>{icon}</Box>}
  </Paper>
);

const InfoBox = ({ title, value }) => (
  <Paper
    sx={{
      background: "#f9f9f9",
      padding: "12px",
      borderRadius: "10px",
      height: "100%",
      boxShadow: "1px 2px 1px 1px rgba(52, 58, 64, 0.2)",
    }}
  >
    <Typography
      sx={{ color: "#87888A", fontSize: "13px", marginBottom: "10px" }}
    >
      {title}
    </Typography>
    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
      {value}
    </Typography>
  </Paper>
);

export function LeftPart({ data }) {
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={0.5} sx={{ margin: "10px" }} alignItems="center">
      <Grid item xs={12}>
        <StatCard
          title="Total Patients"
          value={data?.totalPatients ? data?.totalPatients : 0}
          icon={<TotalPatientsSvg size={55} />}
          //   sx={{
          //     justifyContent: isMobile ? "space-between" : "flex-start",
          //     padding: isMobile ? "10px 10px" : "10px 20px",
          //   }}
        />
      </Grid>

      {/* <Grid item xs={6}>
				<InfoBox title="30 Day Readmit" value={0} />
			</Grid>

			<Grid item xs={6}>
				<InfoBox title="30 Day ED" value={0} />
			</Grid> */}
    </Grid>
  );
}
