import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { toast } from "react-toastify";
import UnknownMedRefillAgent from "./UnknownCallDetailView";
import { getTokenFromLocalStroage } from "../../../../utils";
import { useQueueFilteredDetailsMutation } from "../../../../store/patientQueuesSlice";

const UnknownContentDrawer = ({
  type,
  isFullScreen,
  queueId,
  setUnknownPatientToggleOption,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [summary, setSummary] = useState(null);
  const [filteredQueueData, setFilteredQueueData] = useState([]);
  const [queueFilteredDetails] = useQueueFilteredDetailsMutation();

  const handleQueueSummary = async (bodyData) => {
    if (!bodyData || bodyData.length === 0) {
      return;
    }

    let body;

    if (type === "message") {
      body = {
        user_id: null,
        messages: bodyData?.map((item) => ({
          role: item?.senderId === "chatbot" ? "assistant" : "user",
          content: item?.body,
          timestamp: item?.createdAt,
        })),
        transcripts: null,
        forms: null,
      };
    } else if (type === "call") {
      body = {
        user_id: null,
        messages: null,
        transcripts: bodyData?.map((item) => ({
          transcript: item?.transcript,
        })),
        forms: null,
      };
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_LLM_URL}/summarize/queue`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenFromLocalStroage()}`,
          },
          body: JSON.stringify(body),
        },
      );

      if (response.status === 422) {
        setSummary(
          "We encountered an issue while processing your request. Please check the data and try again. 🙂",
        );
        return; // Early exit if the 422 error is encountered
      }

      if (!response.body) {
        throw new Error("No response body");
      }
      setSummary("");
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let content = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        content += chunk;

        // Process or display chunks incrementally
        setSummary((prev) => prev + chunk); // Append to the summary state
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
  };

  const getPatientQueueData = () => {
    const body = {
      queueType: type,
      patientQueueIds: [queueId],
    };

    queueFilteredDetails({
      body,
    })
      .then((res) => {
        setFilteredQueueData(
          res?.data?.data
            ? res?.data?.data?.[
                type === "call" ? "calls" : type === "message" ? "chats" : type
              ]
            : [],
        );
      })
      .catch((err) => {
        setFilteredQueueData([]);
      });
  };

  useEffect(() => {
    getPatientQueueData();
  }, [type]);

  useEffect(() => {
    if (filteredQueueData) {
      handleQueueSummary(filteredQueueData);
    }
  }, [filteredQueueData]);

  return (
    <div className="content-drawer">
      {!isFullScreen && (
        <Accordion
          sx={{
            border: "1px solid #e8e8e8",
            boxShadow: "none",
            borderRadius: "8px",
          }}
          expanded={isExpanded}
        >
          <AccordionSummary
            onClick={() => setIsExpanded(!isExpanded)}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              margin: 0,
              borderBottom: "1px solid #e8e8e8",
              "&.Mui-expanded": {
                minHeight: "45px", // Set the min-height to 45px when expanded
              },
              "& .MuiAccordionSummary-content": {
                margin: 0, // Removes the margin completely
              },
              "&.Mui-expanded .MuiAccordionSummary-content": {
                margin: 0, // Ensures no margin is added when expanded
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography className="accordion-heading">AI Summary</Typography>
              <Button
                onClick={() => {
                  if (type === "call") {
                    setUnknownPatientToggleOption("calls");
                  } else {
                    setUnknownPatientToggleOption("messages");
                  }
                }}
                variant="outlined"
                //   color="secondary"
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #B3B3B3",
                  background: "#F7F7F7",
                  color: "#1D1D1D",
                  fontSize: "14px",
                  fonteight: "400",
                  lineHeight: "100%",
                  lettrSpacing: "0.14px",
                  padding: "6px 10px",
                  marginLeft: "12px",
                  textTransform: "uppercase",
                }}
              >
                Go To {type === "call" ? "Call" : "Messages"}
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            className="accordion-details"
            sx={{
              padding: "8px",
            }}
          >
            {filteredQueueData ? (
              <Typography>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: (props) => (
                      <a
                        href={props.href}
                        style={{ color: "#000" }}
                        target="blank"
                      >
                        {props.children}
                      </a>
                    ),
                  }}
                >
                  {summary
                    ? summary?.replace(/^```|```$/g, "")?.replace(/\n/g, "  \n")
                    : "Fetching Summary..."}
                </ReactMarkdown>
              </Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{ fontSize: "16px" }}
                >
                  No Summary Found
                </Typography>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <UnknownMedRefillAgent
        {...{
          type,
          data: filteredQueueData,
        }}
      />
    </div>
  );
};

export default UnknownContentDrawer;
