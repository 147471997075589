import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Chrono } from "react-chrono";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { useGetCallAgentsQuery } from "../../../store/callSlice";
import CallAccordion from "../../../Pages/Patient/PatientProfile/CallDrawers/CallAccordion";
import { AudioPlayer } from "../../../Components/AudioPlayer";
import { CallMade, CallReceived, Person, SmartToy } from "@mui/icons-material";

const findFormattedKeyById = (data, id) => {
  if (data) {
    const key = Object?.keys(data)?.find((key) => data[key] === id);

    if (key) {
      const formattedKey = key
        ?.replace(/_/g, " ")
        ?.replace(/\b\w/g, (char) => char?.toUpperCase());
      return formattedKey;
    } else {
      return "N/A";
    }
  } else {
    return "N/A";
  }
};

const getChipColor = (status) => {
  switch (status) {
    case "Neutral":
      return "warning";
    case "Negative":
      return "error";
    case "Positive":
      return "success";
    default:
      return "default"; // fallback color
  }
};

const ContentAccordion = ({
  item,
  agentData,
  defaultExpanded,
  handlePatientRefetch,
}) => {
  return (
    <Accordion
      sx={{ border: "none", boxShadow: "none" }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Grid container spacing={6} alignItems={"center"}>
          <Grid item xs={12} sm={2.4}>
            <Typography variant="subtitle2" fontWeight={600}>
              Initiated By:
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                backgroundColor:
                  item?.initiatedBy === "human" ? "#E8F0FE" : "#F3E8FF",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              {item?.initiatedBy === "human" ? (
                <Person
                  sx={{
                    fontSize: 16,
                    color: "#0049C6",
                  }}
                />
              ) : (
                <SmartToy
                  sx={{
                    fontSize: 16,
                    color: "#7B3FE4",
                  }}
                />
              )}
              <Typography
                variant="body2"
                sx={{
                  textTransform: "capitalize",
                  color: item?.initiatedBy === "human" ? "#0049C6" : "#7B3FE4",
                  fontWeight: 500,
                }}
              >
                {item?.initiatedBy || "N/A"}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Typography variant="subtitle2" fontWeight={600}>
              Agent Name:
            </Typography>
            <Typography variant="body2">
              {findFormattedKeyById(agentData, item?.agentId)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Typography variant="subtitle2" fontWeight={600}>
              Call Picked-up:
            </Typography>
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              {item?.callPicked
                ? "Yes"
                : item?.callPicked === false
                  ? "No"
                  : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Typography variant="subtitle2" fontWeight={600}>
              Recording:
            </Typography>
            <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
              {item?.recordingUrl ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2.4}>
            <Typography variant="subtitle2" fontWeight={600}>
              Direction:
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                backgroundColor:
                  item?.direction === "outbound" ? "#FFF0E6" : "#E8FFF3",
                padding: "4px 8px",
                borderRadius: "4px",
              }}
            >
              {item?.direction === "outbound" ? (
                <CallMade
                  sx={{
                    fontSize: 16,
                    color: "#FF6B00",
                  }}
                />
              ) : (
                <CallReceived
                  sx={{
                    fontSize: 16,
                    color: "#00A86B",
                  }}
                />
              )}
              <Typography
                variant="body2"
                sx={{
                  textTransform: "capitalize",
                  color: item?.direction === "outbound" ? "#FF6B00" : "#00A86B",
                  fontWeight: 500,
                }}
              >
                {item?.direction || "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Divider
          sx={{ background: "#ffffff", width: "100%", marginBottom: "20px" }}
        />
        <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
          <Typography
            variant="h6"
            fontSize={"16px"}
            sx={{ mr: 2 }}
            fontWeight={600}
          >
            Contact Category
          </Typography>
          <Chip
            label={
              item?.patientQueue?.taskCategory
                ?.map(
                  (category) =>
                    category.charAt(0).toUpperCase() + category.slice(1),
                )
                .join(", ") || "No Contact Category"
            }
            size="small"
            color={
              item?.patientQueue?.taskCategory?.length > 0
                ? "success"
                : "warning"
            }
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
          <Typography
            variant="h6"
            fontSize={"16px"}
            sx={{ mr: 2 }}
            fontWeight={600}
          >
            Caller Identification
          </Typography>
          <Chip
            label={item?.patientQueue?.metadata?.callerIdentification || "N/A"}
            size="small"
            color={"success"}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
          <Typography
            variant="h6"
            fontSize={"16px"}
            sx={{ mr: 2 }}
            fontWeight={600}
          >
            User Sentiments
          </Typography>
          <Chip
            label={item?.metadata?.call_analysis?.user_sentiment || "N/A"}
            size="small"
            color={getChipColor(item?.metadata?.call_analysis?.user_sentiment)}
          />
        </Box>
        <Grid container sx={{ marginBottom: "20px" }}>
          {item?.noteId?.description ? (
            <Grid item xs={12} sm={12}>
              <CallAccordion
                title={"Note"}
                data={item?.noteId?.description}
                isCallTab={true}
                noteData={item?.noteId}
                fetchAgain={handlePatientRefetch}
              />
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" fontSize={"16px"} fontWeight={600}>
                  Call Note :
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  {item?.noteId?.description || "No Call Note To Show"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container sx={{ marginBottom: "20px" }}>
          {item?.metadata?.call_analysis?.call_summary ? (
            <Grid item xs={12} sm={12}>
              <CallAccordion
                title={"Summary"}
                data={item?.metadata?.call_analysis?.call_summary}
                isCallTab={true}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" fontSize={"16px"} fontWeight={600}>
                  Call Summary :
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  {item?.summary || "No Call Summary To Show"}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container sx={{ marginBottom: "20px" }}>
          {item?.transcriptObject ? (
            <Grid item xs={12} sm={12}>
              <CallAccordion
                title={"Transcript"}
                data={item?.transcriptObject || []}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" fontSize={"16px"} fontWeight={600}>
                  Transcript :
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2">No Transcript To Show</Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container sx={{ marginBottom: "20px" }}>
          {item?.recordingUrl ? (
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" fontSize={"16px"} sx={{ mb: 2 }}>
                Call Recording
              </Typography>
              <AudioPlayer url={item?.recordingUrl} />
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" fontSize={"16px"} fontWeight={600}>
                  Call Recording :
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  No Call Recording
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const customContent = (dummyCallData, agentData, handlePatientRefetch) => {
  return dummyCallData.map((data, index) => (
    <Box key={index} sx={{ minWidth: "100%" }}>
      <ContentAccordion
        item={data}
        agentData={agentData}
        defaultExpanded={false}
        handlePatientRefetch={handlePatientRefetch}
      />
    </Box>
  ));
};

const CustomTitle = ({ dateTime }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="subtitle" color="textSecondary" fontSize={"14px"}>
        {moment(dateTime).format("DD-MMM-YY")}
      </Typography>
      <Typography variant="body2" color="textSecondary" fontSize={"12px"}>
        {moment(dateTime).format("h:mm A")}
      </Typography>
    </Box>
  );
};

export default function CallSection({
  data,
  isFullScreen,
  getPatientQueueData,
}) {
  const [timelineItems, setTimelineItems] = useState([]);

  const { data: agentData, isLoading: isLoadingAgent } =
    useGetCallAgentsQuery();

  useEffect(() => {
    if (data) {
      const timelineDataReturn = data?.map((item) => {
        return { title: <CustomTitle dateTime={item?.createdAt} /> };
      });
      setTimelineItems(timelineDataReturn);
    }
  }, [data]);

  return (
    <Box
      sx={{
        overflowY: "scroll",
        overflowX: "hidden",
      }}
      className="DialogContent"
    >
      {data?.length > 0 ? (
        <Box>
          <Chrono
            key={timelineItems?.length}
            items={timelineItems}
            hideControls
            disableClickOnCircle
            mode="VERTICAL"
            disableAutoScrollOnClick={true}
            scrollable={false}
            cardHeight={100}
            theme={{ primary: "#0049C6", secondary: "#f5f5f5" }}
            cardPosition="top"
          >
            {customContent(
              data ? data : [],
              agentData ? agentData : {},
              getPatientQueueData,
            )}

            <Box className="chrono-icons">
              {timelineItems.map((item, index) => (
                <CircleIcon
                  key={index}
                  sx={{ color: "#0049C6", fontSize: "20px" }}
                />
              ))}
            </Box>
          </Chrono>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100px",
          }}
        >
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ fontSize: "16px" }}
          >
            No Record Found
          </Typography>
        </Box>
      )}
    </Box>
  );
}
