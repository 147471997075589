import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removePersistedData } from "../store";
import { resetUser } from "../store/userSlice";
import { clearLocalStroage } from "../utils";

export function InactivityCheck() {
  const [state, setState] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(null);

  const navigate = useNavigate();
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    logout();
    dispatch(resetUser());
    clearLocalStroage();
    // persistor.purge() // Delete persisted data
    await removePersistedData(); // Delete persisted data
    // await purgeStoredState(persistor); // Delete persisted data
    navigate("/");
  };

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 60 * 6,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (remaining === 0) {
      logoutUser();
    }
  }, [remaining]);

  return <div />;
}
