import { Chip, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { PatientsSvg } from "../../assets/svgs";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // White background
    color: "#000000", // Black text
    border: "1px solid #ccc", // Optional border
    padding: theme.spacing(1),
    fontSize: "0.875rem",
    boxShadow: theme.shadows[1],
  },
}));

export default function CareProgram({
  program,
  isClickable,
  togglePatientProfileDrawer,
  patient,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        alignContent: "center",
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          marginRight: "5px",
        }}
      >
        <Chip
          label={
            program.length > 0
              ? (() => {
                  const formattedText = program[0]
                    .replace(/_/g, " ")
                    .split(/\s+/)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                  const maxLength = 15; // Set the maximum length for the text
                  return formattedText.length > maxLength
                    ? `${formattedText.substring(0, maxLength)}...`
                    : formattedText;
                })()
              : "No Care Pathway"
          }
          size="medium"
          icon={<PatientsSvg fill="#6235ED" size={18} />}
          sx={{
            marginTop: "-0.2em",
            color: "black",
            backgroundColor:
              program.length > 0 ? "#F6F4FA" : "rgba(255, 31, 0, 0.08)",
            borderRadius: "4px",
            fontWeight: 400,
            paddingLeft: 1,
            cursor: isClickable && "pointer",
          }}
          onClick={(event) => {
            event.stopPropagation();
            if (isClickable) {
              togglePatientProfileDrawer({
                value: true,
                patient,
                isJourney: true,
              });
            }
          }}
        />
      </div>
      {program?.length > 1 && (
        <CustomTooltip
          title={
            program.length > 1 ? (
              <div>
                {program.slice(1).map((word, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#6235ED", // Bullet color
                        borderRadius: "3px", // Rounded corners
                        marginRight: "8px",
                      }}
                    />
                    <Typography
                      component="span"
                      sx={{
                        textDecoration: "underline", // Underline only text
                        textUnderlineOffset: "2px",
                      }}
                    >
                      {word
                        .replace(/_/g, " ")
                        .split(/\s+/)
                        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                        .join(" ")}
                    </Typography>
                  </div>
                ))}
              </div>
            ) : (
              "No additional data"
            )
          }
        >
          <Chip
            label={`+ ${program.length > 1 && program.length - 1}`}
            sx={{
              borderRadius: "4px",
              backgroundColor: "#F5F5F5",
              color: "black",
              fontWeight: 400,
              cursor: isClickable && "pointer",
            }}
            onClick={
              isClickable &&
              togglePatientProfileDrawer({
                value: true,
                patient,
                isJourney: true,
              })
            }
          />
        </CustomTooltip>
      )}
    </div>
  );
}
