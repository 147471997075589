import { Box } from "@mui/material";
import PatientSort from "../../../Components/PatientSort";
import { useGetPatientQueueBadgeCountQuery } from "../../../store/patientQueuesSlice";
import { messagesColumn } from "../../patientQueue/QueueUtils";
import MarkAsResolved from "./MarkAsResolvedUI";
import QueueTabsV2 from "./QueueTabsV2";
import { PatientListingCard } from "../../Patient/PatientTable/PatientListingCard";

export function PatientQueue({
  column,
  queueData,
  queries,
  setQueries,
  selectedPatientIds,
  setSelectedPatientIds,
  refetchQueueAgain,
  page,
  setPage,
  handleSorting,
  sortPatientOrder,
  sortAcuityOrder,
  selectedValue,
  setSelectedValue,
  isNotDashboard,
  sortTaskCategoryOrder,
  sortPhysicianNameOrder,
  sortProcedureTypeOrder,
  sortQueueTypeOrder,
  sortSocialNeedsOrder,
  sortContactTimeOrder,
  isV2Profile,
  sortField,
  setSortField,
  sortOrder,
  setSortOrder,
  patientListRefetch,
}) {
  const { data, isLoading, refetch } = useGetPatientQueueBadgeCountQuery();

  console.log("selectedPatientIds", selectedPatientIds);

  const refetchCount = () => {
    refetch();
  };

  if (isLoading) return <>{console.log("Loading...")}</>;

  return (
    <Box
      sx={{
        mt: "1rem",
        width: "100%",
      }}
    >
      <div>
        {isV2Profile ? (
          <PatientSort
            selectedPatientIds={selectedPatientIds}
            setSelectedPatientIds={setSelectedPatientIds}
            queries={queries}
            refetchQueueAgain={refetchQueueAgain}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            sortField={sortField}
            setSortField={setSortField}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setPage={setPage}
          />
        ) : selectedPatientIds?.length === 0 ? (
          <QueueTabsV2
            queries={queries}
            setQueries={setQueries}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            isNotDashboard={isNotDashboard}
          />
        ) : (
          <MarkAsResolved
            selectedPatientIds={selectedPatientIds}
            setSelectedPatientIds={setSelectedPatientIds}
            queries={queries}
            refetchQueueAgain={refetchQueueAgain}
          />
        )}
        {/* <QueueTabs /> */}
        <PatientListingCard
          isV2Profile={isV2Profile}
          value={0}
          columns={column ? column : messagesColumn}
          refetchCount={refetchCount}
          acuityCount={data?.patientQueueCount[0]?.queueWithHighAcuityCount}
          queueData={queueData}
          selectedPatientIds={selectedPatientIds}
          setSelectedPatientIds={setSelectedPatientIds}
          page={page}
          setPage={setPage}
          handleSorting={handleSorting}
          sortPatientOrder={sortPatientOrder}
          sortAcuityOrder={sortAcuityOrder}
          refetchQueueAgain={refetchQueueAgain}
          sortTaskCategoryOrder={sortTaskCategoryOrder}
          sortPhysicianNameOrder={sortPhysicianNameOrder}
          sortProcedureTypeOrder={sortProcedureTypeOrder}
          sortQueueTypeOrder={sortQueueTypeOrder}
          sortSocialNeedsOrder={sortSocialNeedsOrder}
          sortContactTimeOrder={sortContactTimeOrder}
          patientListRefetch={patientListRefetch}
        />
      </div>
    </Box>
  );
}
