import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useIsMobile } from "../../contexts/isMobile";
import { useToggleChatMutation } from "../../store/chatSlice";
import { useGetHospitalByIDQuery } from "../../store/hospitalsSlice";
import { getCurrentSiteId, getTokenFromLocalStroage } from "../../utils";
import SnackbarMsg from "../SnackbarMsg";

export const chatbotMode = [
  {
    title: "Autopilot",
    value: "automatic",
    text: "Chatbot manages the conversation with patient.",
  },
  {
    title: "Care-Guard",
    value: "careGuard",
    text: "Edit and approve Chatbot messages before sending.",
  },
  {
    title: "Manual",
    value: "manual",
    text: "Directly manage the conversation with patient.",
  },
];

function ChatBotDropdownButton({
  chatBotEnabled,
  selectedValue,
  setSelectedValue,
  patientId,
  refetch,
  value,
  pathname,
  refetchChat,
  isV2True,
  isRefetchChat,
  setIsRefetchChat,
  patientListRefetch,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBar, setOpenBar] = useState(false);
  const [chatbotModeOptions, setChatbotModeOptions] = useState([]);
  const isMobile = useIsMobile();
  const hospitalId = getCurrentSiteId();

  const [toggleChatbotPreference] = useToggleChatMutation();
  const { data, refetch: refetchHospital } =
    useGetHospitalByIDQuery(hospitalId);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const msg = selectedValue && `${selectedValue} Mode Activated`;
  const msg2 =
    selectedValue === "Care-Guard"
      ? "Chatbot is now in care-guard mode. Review and confirm messages before they are sent."
      : selectedValue === "Manual"
        ? "You are in control. Manual mode enabled for direct patient messaging."
        : selectedValue === "Autopilot"
          ? "Chatbot is autonomously managing the conversation with the patient."
          : "";

  const config = {
    headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
  };

  const changechatbotMode = async (chatModeItem) => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BASE_API_URL}/v2/${value === 0 ? "patient" : "careAlly"}/${patientId}/updateChatMode`,
          {
            chatMode: chatModeItem?.value,
          },
          config,
        )
        .then((res) => {
          setSelectedValue(chatModeItem?.title);
          if (patientListRefetch) {
            patientListRefetch();
          }

          if (refetchChat) {
            refetchChat();
          }

          if (setIsRefetchChat) {
            setIsRefetchChat(true);
          }

          handleBar();
          handleClose();
        });
    } catch (error) {
      console.log(error);
      toast.error("Error:" + error);
    }
  };

  const handleBar = () => {
    setOpenBar(true);
  };
  const handleBarClose = () => {
    setOpenBar(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (itemValue) => {
    // const isTrue = itemValue?.value === 'manual' ? false : true;
    changechatbotMode(itemValue);
    // toggleChatbotPreference({ id: patientId, chatBotEnabled: isTrue });
  };

  React.useEffect(() => {
    if (chatBotEnabled) {
      const chatbotModeData = chatbotMode?.find(
        (item) => item?.value === chatBotEnabled,
      );
      setSelectedValue(chatbotModeData?.title);
    } else {
      console.log(value);
      setSelectedValue(chatbotMode[1].title);
    }
  }, [chatBotEnabled, value]);

  React.useEffect(() => {
    if (data) {
      const featureFlags = data?.data?.config.featureFlags;

      if (featureFlags[0] === "disableAutopilot") {
        const filteredOptions = chatbotMode.filter(
          (item) => item?.value !== "automatic",
        );
        setChatbotModeOptions(filteredOptions);
      } else {
        setChatbotModeOptions(chatbotMode);
      }
    } else {
      setChatbotModeOptions(chatbotMode);
    }
  }, [data]);

  React.useEffect(() => {
    refetchHospital();
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        sx={{
          borderColor: "#7A869A",
          color: "#091E42",
          borderRadius: "3px",
          backgroundColor: "#FAFBFC",
          textTransform: "capitalize",
          fontWeight: 600,
          p:
            (pathname === "dashboard" || pathname === "queue") &&
            selectedValue === "Care-Guard"
              ? "8px 11px"
              : "8px 20px",
          mt: isMobile && 1,
          fontSize: isV2True && "12px",
        }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon sx={{ marginLeft: "8px" }} />}
      >
        {selectedValue}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {chatbotModeOptions?.map((item) => (
          <>
            <Typography
              key={item.title}
              sx={{
                py: "5px",
                px: "15px",
                cursor: "pointer",
                color: "black",
                width: "300px",
              }}
              onClick={() => handleSelect(item)}
            >
              {item.title}

              <Typography sx={{ cursor: "pointer", color: "#979797" }}>
                {item.text}
              </Typography>
            </Typography>
            <Divider sx={{ width: "90%", p: 1 }} />
          </>
        ))}
      </Popover>

      <SnackbarMsg
        msg={msg}
        msg2={msg2}
        open={openBar}
        handleClose={handleBarClose}
        width={"390px"}
      />
    </>
  );
}

export default ChatBotDropdownButton;
