import React from 'react';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const SavedFiltersSelect = ({
  savedFilters = [],
  selectedFilter,
  onFilterSelect,
  onEditFilter,
  onDeleteFilter,
}) => {

  const renderValue = (selected) => {
    const filter = savedFilters.find(f => f._id === selected);
    return filter ? filter.name : 'Select filter';
  };

  const handleIconClick = (e, callback, filter) => {
    e.stopPropagation();
    callback(filter);
  };

  return (
    <Box>
      <Typography variant='body2'>
        Saved Filters
      </Typography>
      <Select
        sx={{ mt: 0.5 }}
        value={selectedFilter || ''}
        size='small'
        onChange={(e) => onFilterSelect(e.target.value)}
        displayEmpty
        fullWidth
        IconComponent={KeyboardArrowDownIcon}
        renderValue={renderValue}
      >
        <MenuItem value="" sx={{ color: '#6B7280' }}>
          Select filter
        </MenuItem>
        {savedFilters.map((filter) => (
          <MenuItem 
            key={filter._id} 
            value={filter._id}
            sx={{ 
              color: '#111827',
              '&:hover': {
                backgroundColor: '#F3F4F6'
              },
              display: 'flex',          
              justifyContent: 'space-between',
              alignItems: 'center' 
            }}
          >
            <span>{filter.name}</span>
            <Box sx={{ ml: 1 }}>
              <IconButton
                size="small"
                onClick={(e) => handleIconClick(e, onEditFilter, filter)}
                sx={{
                  mr: 0.5,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => handleIconClick(e, onDeleteFilter, filter)}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Box>
          </MenuItem>
        ))}

      </Select>
    </Box>
  );
};

export default SavedFiltersSelect;