import { LaunchRounded } from "@mui/icons-material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useIsAuthenticatedUser } from "../../contexts/isAuthenticatedUser";
import { useResendConsentMutation } from "../../store/chatSlice";
import { ConfirmationModal } from "./ConfirmationModal";

export function MessageHeader({
  name,
  path,
  acuity,
  patient,
  title,
  value,
  careAllyData,
  id,
  refetchChat,
  status,
  consent,
}) {
  const { user } = useIsAuthenticatedUser();
  // States and Functions for Message Drawer
  const [open, setOpen] = React.useState(false);

  const [resendConsent] = useResendConsentMutation();
  const navigate = useNavigate();

  const getColorStyles = (acuity) => {
    if (acuity === "high") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else if (acuity === "medium") {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    } else if (acuity === "N/A") {
      return {
        marginRight: "5px",
        color: "black",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    }
  };
  const getAge = (birthDate) => {
    if (birthDate) {
      const age = Math.floor(
        (new Date() - new Date(birthDate).getTime()) / 3.15576e10,
      );
      return `${age}Y`;
    } else {
      return "No DOB Available";
    }
  };

  const getConsentColors = (consent) => {
    if (consent === "yes") {
      return {
        marginRight: "5px",
        color: "#63A02C",
      };
    } else if (consent === "no") {
      return {
        marginRight: "5px",
        color: "#DC362E",
      };
    } else {
      return {
        marginRight: "5px",
        color: "#FFB400",
      };
    }
  };
  const [openResend, setOpenResend] = useState(false);
  const handleResend = () => {
    setOpenResend(true);
  };
  const getSmsConsent = (data) => {
    return data?.consentResponse === "NO_RESPONSE"
      ? "No Response"
      : data?.consentResponse?.charAt(0).toUpperCase() +
          data?.consentResponse?.slice(1);
  };

  const handleDataResend = async () => {
    if (status === "active") {
      const response = await resendConsent(id);
      if (response?.data?.message) {
        toast.success("The SMS consent has been resent.");
        refetchChat();
        setOpenResend(false);
      } else {
        toast.error("Something went wrong!");
      }
    } else {
      toast.error("The patient status is currently inactive.");
    }
  };
  const goToProfile = (id, name) => {
    localStorage.setItem("pageName", JSON.stringify(name));
    navigate(`/profile/${id}`, { state: { action: "read-only", id } });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        // border: "1px solid rgba(0, 151 ,236 ,0.30)",
        bgcolor: "rgba(0, 151 ,236 ,0.04)",
        p: 2,
      }}
    >
      <Stack direction="row" alignItems="center">
        <Avatar
          sx={{ width: "50px", height: "50px" }}
          alt="Profile Image"
          src={
            path
              ? `${process.env.REACT_APP_BASE_API_URL}/${path}`
              : user?.picture
          }
        />
        <Grid
          container={true}
          spacing={2}
          style={{
            paddingLeft: "5px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Grid item md={4} xs={3}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {careAllyData && value === 2
                  ? careAllyData?.firstName || careAllyData?.lastName
                    ? `${careAllyData?.firstName} ${careAllyData?.lastName}`
                    : "N/A"
                  : name}
              </Typography>
              <Tooltip title="Go to Profile">
                <IconButton
                  sx={{ ml: "3px" }}
                  size="small"
                  onClick={() => {
                    goToProfile(patient?._id, "patient");
                  }}
                >
                  <LaunchRounded fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#50607A",
                  pr: 1,
                }}
              >
                {careAllyData && value === 2
                  ? careAllyData?.relationship || "N/A"
                  : `${getAge(patient?.birthday)}, ${
                      patient?.gender?.charAt(0)?.toUpperCase() || "N/A"
                    }`}
              </Typography>
              {title !== "patients" && title !== "profile" && (
                <>
                  <span style={{ ...getColorStyles(acuity), fontSize: "14px" }}>
                    •
                  </span>
                  <Typography fontSize={14} color="black">
                    {acuity?.charAt(0).toUpperCase() + acuity?.slice(1)} Acuity
                  </Typography>
                </>
              )}
            </div>
          </Grid>

          <Divider orientation="vertical" flexItem={true} />
          <Grid item={true} md={4} xs={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                width: "100%",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#50607A",
                }}
              >
                Contact
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CallOutlinedIcon
                  sx={{
                    fontSize: "14px",
                    color: "#536878",
                    marginRight: "5px",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {careAllyData && value === 2
                    ? careAllyData?.phoneNumber
                    : patient?.phone}
                </Typography>
              </div>
              {(value !== 2 || !careAllyData) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <EmailOutlinedIcon
                    sx={{
                      fontSize: "14px",
                      color: "#536878",
                      marginRight: "5px",
                    }}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {patient?.email}
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
          <Divider orientation="vertical" flexItem={true} />
          <Grid item={true} md={3} xs={3}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                width: "100%",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#50607A",
                }}
              >
                SMS Consent
                {value !== 2 && consent !== "yes" && (
                  <Tooltip title="Resend SMS Consent">
                    <IconButton onClick={handleResend}>
                      <ReplayIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <>
                  <span
                    style={{
                      ...(careAllyData && value === 2
                        ? getConsentColors(careAllyData?.consentResponse)
                        : getConsentColors(patient?.consentResponse)),
                      fontSize: "36px",
                    }}
                  >
                    •
                  </span>
                  <Typography fontSize={14} color="black">
                    {careAllyData && value === 2
                      ? getSmsConsent(careAllyData)
                      : getSmsConsent(patient)}
                  </Typography>
                </>
              </div>
            </div>
          </Grid>
          {/* <Grid item md={2}>
            <CallOutlinedIcon />
          </Grid> */}
        </Grid>
      </Stack>
      <ConfirmationModal
        open={openResend}
        setOpen={setOpenResend}
        txt="Are you sure you want to resend the SMS consent?"
        handle={handleDataResend}
      />
    </Paper>
  );
}
