import { Clear, Search } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PatientBreadcrumb } from "../../Pages/Patient/index";
import { KIMISvg } from "../../assets/svgs/KIMI";
import { ROLES } from "../../constants/routes";
import { useChildNode } from "../../contexts/addChildNode";
import { useAssistantChatValues } from "../../contexts/assistantChatValue";
import { useChatValues } from "../../contexts/chatValue";
import { useIsHovered } from "../../contexts/isHovered";
import { socket } from "../../socket";
import {
  useGetNotificationsQuery,
  useViewNotificationMutation,
} from "../../store/notificationsSlice";
import { useGetPatientByIDQuery } from "../../store/patientsSlice";
import { addInParams } from "../../utils";
import { ExternalAIAssistant } from "../ExternalAIAssistant/ExternalAIAssistantDrawer";
import { MessageDrawer } from "../MessageLayout/MessageDrawer";
import { NotificationDrawer } from "../NotificationDrawer";
import OutlinedButton from "../OutlinedButton";
import { Breadcrumb } from "../index";
import { drawerWidth } from "./constant";
import { PatientProfileDrawer } from "../PatientProfile/PatientProfileDrawer";
import Switch from "@mui/material/Switch";

// import { Button } from '@material-ui/core'
export const Header = () => {
  const location = useLocation();

  // Create a URLSearchParams object from the location search string
  const queryParams = new URLSearchParams(location.search);
  const { sid, id } = useParams();
  const { user } = useSelector((state) => state.user);
  const {
    searchTerm,
    setSearchTerm,
    showAnalytics,
    setShowAnalytics,
    showPatientsAnalytics,
    setShowPatientAnalytics,
    searchPatientTerm,
    setSearchPatientTerm,
  } = useChildNode();
  const { setChatMessages, chatMessages } = useChatValues();
  // States for notification
  const [count, setCount] = useState(0);
  const [notification, setNotification] = useState([]);
  const { hovered } = useIsHovered();
  const pl = hovered ? `${drawerWidth + 10}px` : "60px";
  const [page, setPage] = useState(1);
  const { data, refetch } = useGetNotificationsQuery(user.id);
  const [viewNotification] = useViewNotificationMutation();
  const { setAssistantChatMessages } = useAssistantChatValues();

  const refetchAgain = () => {
    refetch();
  };
  const [arrow, setArrow] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [prevTitle, setPrevTitle] = useState("");
  const [backArrow, setBackArrow] = useState(false);
  const [openAIAssistant, setOpenAIAssistant] = useState(false);
  const [curPageTitle, setCurPageTitle] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const currentPageTitle = pathSegments[1];
    setCurPageTitle(currentPageTitle);

    // Set the previous page's title when the location changes
    setPrevTitle(pageTitle);
    if (currentPageTitle === "queue") {
      // Set pageTitle based on currentPageTitle
      setPageTitle("Patient Queue");
    } else if (
      (currentPageTitle === "queueprofile" && prevTitle !== "dashboard") ||
      currentPageTitle === "createProfile"
    ) {
      setBackArrow(true);
      setPageTitle(prevTitle || "Patient Queue Profile");
    } else if (
      currentPageTitle === "queueprofile" &&
      prevTitle === "dashboard"
    ) {
      setBackArrow(true);
      setPageTitle("Patient Queue Profile");
    } else if (currentPageTitle === "patients") {
      setBackArrow(false);
      setPageTitle("Patient Management");
    } else if (currentPageTitle === "pros") {
      setBackArrow(false);
      setPageTitle("Patient Reported Outcome (PRO) Insights");
    } else if (currentPageTitle === "social-care-orchestration") {
      setBackArrow(false);
      setPageTitle("social Care Orchestration");
    } else if (currentPageTitle === "social-care-queue") {
      setBackArrow(false);
      setPageTitle("social Care Queue");
    } else {
      setBackArrow(false);
      setPageTitle(currentPageTitle);
    }
  }, [location.pathname, pageTitle]);

  const pid = location.pathname.split("/").reverse()[0];

  // varibles for arrow button
  const formEdit = location.pathname.split("/")[2];
  const formCreate = location.pathname;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  // States and Functions for Notification Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    viewNotification().then(() => {
      refetch();
    });
    setOpen(open);
  };

  function getRoleName(roleId) {
    for (const [roleName, id] of Object.entries(ROLES)) {
      if (id === roleId) {
        const formattedRoleName = roleName.replace(/([a-z])([A-Z])/g, "$1 $2");
        return formattedRoleName;
      }
    }
    return "Role not found";
  }

  useEffect(() => {
    localStorage.setItem("showAnalytics", JSON.stringify(showAnalytics));
  }, [showAnalytics]);

  useEffect(() => {
    localStorage.setItem(
      "showPatientsAnalytics",
      JSON.stringify(showPatientsAnalytics),
    );
  }, [showPatientsAnalytics]);

  useEffect(() => {
    if (formEdit === "edit" || formCreate === "/forms/create") {
      setArrow(true);
    } else {
      setArrow(false);
    }
  }, [formEdit, formCreate]);

  // useEffect for notification
  useEffect(() => {
    if (data) {
      setNotification(data.data);
    }
  }, [data]);

  useEffect(() => {
    socket.on("Notification", (notificationData) => {
      setNotification((prevNotification) => [
        ...prevNotification,
        notificationData,
      ]);
    });
  }, []); // Only run once on mount

  useEffect(() => {
    const filteredCount = notification.filter((item) => !item?.isCount);
    const countLength = filteredCount.length;
    setCount(countLength);
  }, [notification, count]);

  const [openMsg, setOpenMsg] = useState(false);
  const {
    data: patient,
    isLoading,
    refetch: refetchPatient,
  } = useGetPatientByIDQuery(id, { refetchOnMountOrArgChange: true });

  const toggleMsgDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMsg(open);
    setChatMessages([]);
  };
  const toggleAIDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setAssistantChatMessages([]);
    setOpenAIAssistant(open);
  };
  const refetchPatientAgain = () => {
    refetchPatient();
  };

  useEffect(() => {
    if (queryParams.get("name")) {
      if (curPageTitle === "dashboard") {
        setSearchTerm(queryParams.get("name"));
      } else if (curPageTitle === "patients") {
        setSearchPatientTerm(queryParams.get("name"));
      }
    }
  }, [curPageTitle]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={arrow && "center"}
        sx={{
          py: "10px",
          px: "40px",
          bgcolor: "white",
          position: "fixed",
          top: 0,
          zIndex: 900,
          left: "2rem",
          width: "100%",
          borderBottom: "1px solid #9797971a",
          pl,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: (arrow || backArrow) && "flex",
              alignItems: (arrow || backArrow) && "center",
            }}
          >
            {(arrow || backArrow) && (
              <ArrowBackIcon
                sx={{ cursor: "pointer", marginRight: "10px" }}
                onClick={handleGoBack}
              />
            )}
            <Typography
              sx={{
                justifyContent: "center",
                textTransform: "capitalize",
                fontSize: "1.5em",
                fontWeight: 500,
              }}
            >
              {pageTitle !== "patientSurveys" &&
              pageTitle !== "profile" &&
              pageTitle !== "editProfile" ? (
                pageTitle
              ) : pageTitle === "profile" || pageTitle === "editProfile" ? (
                <PatientBreadcrumb id={id} />
              ) : (
                <Breadcrumb surveyid={id} />
              )}
            </Typography>
          </Box>
          {curPageTitle === "dashboard" && (
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          )}
          {curPageTitle === "dashboard" && (
            <FormControlLabel
              control={
                <Switch
                  checked={showAnalytics}
                  onChange={() => setShowAnalytics((prev) => !prev)}
                />
              }
              label="Show Analytics"
            />
          )}
          {curPageTitle === "patients" && (
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          )}
          {curPageTitle === "patients" && (
            <FormControlLabel
              control={
                <Switch
                  checked={showPatientsAnalytics}
                  onChange={() => setShowPatientAnalytics((prev) => !prev)}
                />
              }
              label="Show Analytics"
            />
          )}
        </Box>

        {/* Commenting out the Icons part in navbar */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {(curPageTitle === "dashboard" || curPageTitle === "queue") && (
            <Box sx={{ marginRight: 2 }}>
              <TextField
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  addInParams("name", e.target.value);
                }}
                placeholder="Search"
                variant="filled"
                InputProps={{
                  disableUnderline: true, // Removes the bottom border
                  sx: {
                    height: 37,
                    borderRadius: "4px",
                  },
                  startAdornment: (
                    <InputAdornment position="center">
                      <Search
                        sx={{ color: "rgb(0, 0, 0, 0.87)", marginRight: 1 }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSearchTerm("");
                          addInParams("name", ""); // Reset search param
                        }}
                      >
                        <Clear sx={{ color: "rgb(0, 0, 0, 0.87)" }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                sx={{
                  width: "370px",
                  "& .MuiInputBase-input": {
                    paddingTop: 0, // Adjust padding if needed
                    paddingBottom: 0, // Adjust padding if needed
                  },
                }}
              />
            </Box>
          )}
          {curPageTitle === "patients" && (
            <Box sx={{ marginRight: 2 }}>
              <TextField
                value={searchPatientTerm}
                onChange={(e) => {
                  setSearchPatientTerm(e.target.value);
                  addInParams("name", e.target.value);
                }}
                placeholder="Search"
                variant="filled"
                InputProps={{
                  disableUnderline: true, // Removes the bottom border
                  sx: {
                    height: 37,
                    borderRadius: "4px",
                  },
                  startAdornment: (
                    <InputAdornment position="center">
                      <Search
                        sx={{ color: "rgb(0, 0, 0, 0.87)", marginRight: 1 }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: searchPatientTerm ? (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSearchPatientTerm("");
                          addInParams("name", ""); // Reset search param
                        }}
                      >
                        <Clear sx={{ color: "rgb(0, 0, 0, 0.87)" }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                sx={{
                  width: "370px",
                  "& .MuiInputBase-input": {
                    paddingTop: 0, // Adjust padding if needed
                    paddingBottom: 0, // Adjust padding if needed
                  },
                }}
              />
            </Box>
          )}

          {(pageTitle === "profile" || pageTitle === "EditProfile") && (
            <OutlinedButton
              label={"Message"}
              handleClick={toggleMsgDrawer(true)}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<KIMISvg />}
            onClick={toggleAIDrawer(true)}
            sx={{
              borderRadius: "4px",
            }}
          >
            Ask KIMI
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "auto",
              marginLeft: "10px",
            }}
          >
            <AccountCircleIcon
              sx={{ color: "rgba(0, 0, 0, 0.56)", fontSize: 40 }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "start",
                  color: "grey",
                  fontSize: "12px",
                  fontWeight: 800,
                }}
              >
                {user.email}
              </Typography>
              <Typography
                sx={{ textAlign: "start", color: "grey", fontSize: "12px" }}
              >
                {getRoleName(user.role)}
              </Typography>
            </Box>
          </Box>
          {/* <IconButton aria-label={'more than 99 notifications'} onClick={toggleDrawer(true)}>
						{count === 0 ? (
							<NotificationsNoneIcon sx={{ color: '#979797' }} />
						) : (
							<Badge badgeContent={count} color="secondary">
								<NotificationsNoneIcon />
							</Badge>
						)}
					</IconButton> */}
        </Box>
        <NotificationDrawer
          toggleDrawer={toggleDrawer}
          state={open}
          data={notification}
          refetchAgain={refetchAgain}
        />
        <ExternalAIAssistant
          toggleDrawer={toggleAIDrawer}
          state={openAIAssistant}
        />
        <MessageDrawer
          toggleDrawer={toggleMsgDrawer}
          state={openMsg}
          id={id}
          name={patient?.firstName}
          user={user}
          path={patient?.path}
          patient={patient}
          title={pageTitle}
          consent={patient?.consentResponse}
          page={page}
          setPage={setPage}
          refetchPatientAgain={refetchPatientAgain}
        />

        {openMsg && (
          <PatientProfileDrawer
            state={openMsg}
            toggleDrawer={toggleMsgDrawer}
            name={`${patient?.firstName} ${patient?.lastName}`}
            user={user}
            path={patient?.path}
            patient={patient}
            acuity={patient?.acuity}
            id={patient?._id}
            status={patient?.status}
            consent={patient?.consentResponse}
          />
        )}
      </Stack>
      <Box sx={{ height: "2rem" }} />{" "}
    </>
  );
};
