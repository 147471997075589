import { Box, Typography } from "@mui/material";
import { HelpCenterOutlined } from "@mui/icons-material"; // Example icons
import CallSection from "../CallSection";
import { Conversation } from "../../../../assets/svgs/PatientProfileTabsSvgs/Conversation";
import { Gallery } from "../../../../assets/svgs/PatientProfileTabsSvgs/Gallery";
import { Form } from "../../../../assets/svgs/PatientProfileTabsSvgs/Form";
import { Call } from "../../../../assets/svgs/PatientProfileTabsSvgs/Call";
import { Task } from "../../../../assets/svgs/PatientProfileTabsSvgs/Task";
import ChatSection from "../ChatSection";

const UnknownMedRefillAgent = ({ type, data }) => {
  console.log(data);
  const getIconByKey = (key) => {
    switch (key) {
      case "message":
        return <Conversation color="#424242" size={20} />;
      case "new_media":
        return <Gallery color="#424242" size={20} />;
      case "form_submission":
        return <Form color="#424242" size={20} />;
      case "care_ally_message":
        return <Conversation color="#424242" size={20} />;
      case "dialogue":
        return <Form color="#424242" size={20} />;
      case "call":
        return <Call color="#424242" size={20} />;
      case "task":
        return <Task color="#424242" size={20} />;
      default:
        return <HelpCenterOutlined />; // Default fallback icon
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        background: "#fff",
        overflow: "hidden",
        border: "1px solid #e8e8e8",
        boxShadow: "none",
        borderRadius: "8px",
        marginTop: "14px",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 16px",
          borderBottom: "1px solid #e0e0e0",
          height: "45px",
        }}
      >
        {/* First Section */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "157%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {getIconByKey(type)}
            <span style={{ marginLeft: 4 }}>
              {type
                ? type
                    .toLowerCase()
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : "Loading . ."}
            </span>
          </Typography>
        </Box>
      </Box>

      {type === "call" && <CallSection data={data} />}
      {type === "message" && (
        <Box sx={{ mt: 2 }}>
          <ChatSection
            {...{
              data: data,
              patient: data?.patient,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default UnknownMedRefillAgent;
